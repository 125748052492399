<template>
  <div v-if="!this.loading">
    <div class="row">
      
      <div v-if="this.showFilter" class="col-md-8 d-flex justify-content-start filtro mb-3">

        <!-- SELECCIONES -->
        <div v-if="this.getSelectedItems().length>0" class="mt-2 me-3 dropdown ">
          <label for="exampleFormControlSelect1">Selección ({{this.getSelectedItems().length}})</label>
          <select v-model="stateToChange" class="form-control selectNewStateTable" >
            <option :value="null">✎ Editar Estado </option>
            <!-- <option :value="null">↑ Exportar </option> -->
            <option v-for="state in this.availableStates()" :key="state" :value="state">{{translate(state)}}</option>
          </select>
        </div>
        
        
        
        <!-- BUSCADOR -->
        <div  v-if="stateToChange == null" class="m-2 mb-0">
          <label for="exampleFormControlSelect1"> <i class="bi bi-search"></i> Buscar</label>
          <div class="input-group">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Buscar"
              v-model="search"
            />
          </div>
        </div>

        <div  class="form-group m-2 ms-0 mb-0 dropdown">
          <label for="exampleFormControlSelect1"> <i class="bi bi-boxes"></i> Stocks :</label>
          <select v-model="stockFilter" class="form-control" id="exampleFormControlSelect1">
            <option :value="null">Todos</option>
            <option value="c">Con alertas</option>
            <!-- <option value="existences_soon_out">Sin existencias próximamente</option> -->

            <option value="no_existences">Sin existencias</option>
            <option value="pending_to_confirm">Pendientes de confirmar</option>
          </select>
        </div>



        <div class="col-md-4 d-flex justify-content-start">
        <button class="btn btn-primary  buttonSaveChangesTable"
           v-if="stateToChange !== null && this.getSelectedItems().length>0"
           @click="updateOrdersStatus()"

        >
        <i class="bi bi-pencil-square me-2"></i> Editar {{ this.getSelectedItems().length }} Pedidos
        </button>
      </div>
        
      </div>
      <div  class="col-md-4 d-flex justify-content-end mb-3">

        <!-- CREATE ORDER -->
        <router-link
          :to="{
            name: 'CreateProduct',
          }"
          class="btn btn-primary  ml-auto mt-auto ps-5 pe-5 d-flex mb-0"
           v-if="this.canCreate && this.getSelectedItems().length == 0"

        >
        <i class="bi bi-plus-lg m-auto me-2"></i>  Nuevo Producto
        </router-link>

        <!-- EXPORT BUTTON -->
        <div v-if="this.canCreate && this.getSelectedItems().length > 0 && this.stateToChange == null" class="dropdown d-flex">
          <button @click="this.openExports = !this.openExports"  class="btn btn-primary  ml-auto mt-auto ps-5 pe-5 d-flex mb-0 " >
            <i class="bi bi-arrow-up-right-square me-2"></i> Exportar Productos ({{this.getSelectedItems().length}})
          </button>
          <div :class="{ 'show': openExports }" class="dropdown-menu " id="exportOptions" aria-labelledby="dropdownMenuButton">
            <div class="dropdown-item"> PDF </div>
            <div class="dropdown-item"> EXCEL </div>
            <div class="dropdown-item"> CSV </div>

          </div>
        </div>
        
        
      </div>
    </div>
    
    <div v-if="loading" class="d-flex justify-content-center">
      <div class="spinner-border" rol="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div v-else class="m-1 mb-4" :class="{card: card}">
      <div class="card-body " :class="'p-'+this.padding">
        <div class="table-responsive p-0">
          <table class="table mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 "
                >
                  
                </th>
                <th
                  v-if="!lessData"
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 "
                >
                  SKU
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Nombre
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Stock Entrante
                </th>

                <th
                  v-if="this.$store.state.auth.rol == 'worker' && showUserIno"
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Vendedor
                </th>

                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Alertas
                </th>
                
               
                <th
                  v-if="showPrice"
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  PVP
                </th>
                
                
                <th
                  v-if="showPrice && false"
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Costo
                </th>
                <th
                v-if="!lessData"
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Actualizado
                </th>
                
                <th
                  v-if="canEdit && !lessData"
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Opciones
                </th>
              </tr>
            </thead>
            <div v-if="this.loading" class="d-flex justify-content-center">
              <div class="spinner-border" rol="status"></div>
            </div>
            <tbody >
              <tr v-for="product in this.filteredData()" :key="product.id">

                <!-- SELECT -->
                <td v-if="false" class="text-center">
                  <div v-if="product.loadingNewState?? false">
                    <Cargador :width="36" :fontSize="'10px'"/>
                  </div>
                  <input v-else type="checkbox" class="checkTable" v-model="product.selected"/>
                  <!-- {{ product.client }} -->
                </td>

                <!-- IMG -->
                <td class=" ">
                  <router-link
                    :to="{
                      name: 'ViewProduct',
                      params: {
                        product_id: product.id,
                      },
                    }"
                    >
                    <img class="product-img img-smaller" v-if="product.image_url" :src="product.image_url" alt="">
                    <img v-else class="product-img img-smaller mb-1" :src="this.$store.state.baseUrl+'/products/default.png'" alt="" />

                  </router-link>
                  
                </td>

                <td class=" ">
                  <p class="p-0 m-0 text-xs not-small"  v-html="this.$store.state.dateFormat(product.created_at)">
                     
                    </p>    
                  <router-link
                    :to="{
                      name: 'ViewProduct',
                      params: {
                        product_id: product.id,
                      },
                    }"
                    >{{ product.sku?? '' }}
                  </router-link>
                                  
                    
                </td>


                <!-- NAME -->
                <td class=" ">
                  {{product.name}}
                </td>

                <!-- STOCKS -->
                <td>
                  <div v-if="product.info_stocks.incoming.length > 0">
                    <Stock v-for="stockIn in product.info_stocks.incoming" :key="stockIn.id" :stock="stockIn"></Stock>
                  </div>
                </td>

                <!-- seller -->
                <td v-if="this.$store.state.auth.rol == 'worker' && showUserIno" class=" ">
                  <ClientInfo
                    :showId="false"
                    :data="product.seller"
                  />
                </td>
                <td>
                  <div v-if="product.real_alerts.length > 0" class="d-flex flex-wrap">
                    <div v-for="alert in product.real_alerts" :key="alert.id" class="alertStock text-center">
                      <div class="w-100">&lt; {{ alert.alert_on }}</div>
                      <div v-if="alert.type == 'unit'" class="w-100 text-sm">Unidades</div>
                      <div v-if="alert.type == 'box'" class="w-100 text-sm">Cajas</div>

                    </div>
                  
                  </div>
                  <div v-else class="text-center">
                    -
                  </div>
                  
                </td>
                <!-- PVP -->
                <td v-if="showPrice" class=" ">
                  <p class="text-sm font-weight-bold mb-0">{{ (product.real_client_price)  }}€</p>
                </td>
                <!-- COSTE -->
                <td v-if="showPrice && false" class=" ">
                  <p class="text-sm font-weight-bold mb-0">{{ (product.real_seller_price)  }}€</p>
                </td>
                <!-- UPDATED -->
                <td v-if="!lessData" class=" ">
                  <div class="d-flex flex-column align-right">
                    <p v-html="this.$store.state.dateFormat(product.updated_at)" class="p-0 m-0 text-right text-sm">
                    </p>
                  </div>
                </td>
                <td v-if="canEdit" class=" ">
                 <router-link
                 class="m-1"
                 v-if="!lessData"
                   :to="{
                     name: 'ViewProduct',
                     params: {
                       product_id: product.id,
                     },
                   }"
                   >
                   <svg fill="#67748e" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
                 </router-link>
                 <router-link
                 class="m-1"
                 v-if="!lessData"
                   :to="{
                     name: 'EditProduct',
                     params: {
                       product_id: product.id,
                     },
                   }"
                   >
                   <svg fill="#67748e" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                 </router-link>
                 
                 </td>


               
                
               
              </tr>
              <div v-if="this.filteredData().length<1" class="">
                <h5 class="m-3 w-100">No hay resultados</h5>
                <img width="300" class="m-auto" :src="notFound" alt="" />
              </div>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="showLoader" class="d-flex justify-content-center">
    <Cargador></Cargador>
  </div> 
</template>

<script>
import ClientInfo from "@/views/components/ClientInfo.vue";
import axios from "axios";
import notFound from "@/assets/img/not-found.gif";
import Stock from "@/views/components/Stock.vue";


export default {
  name: "orders-table",
  components: {
    ClientInfo,
    Stock
  },
  props: {
    showLoader: {
      type: Boolean,
      default: true,
    },
    showUserIno: {
      type: Boolean,
      default: true,
    },
    card: {
      type: Boolean,
      default: true,
    },
    padding: {
      default: 2
    },

    showSeller: {
      type: Boolean,
      default: true,
    },
    lessData: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      default: true,
    },
    showPrice: {
      default: true,
    },
    product_id: {
      default: false,
    },
    seller_id: {
      default: false
    },
    canCreate: {
      default: true,
    },
    canEdit: {
      default: true,
    },
    stateFilterInit: {
      default: 'all'
    }
  },
  data() {
    return {
      openExports: false,
      notFound,
      created: "",
      updated_at: "",
      createdDateTime: {},
      updatedDateTime: {},
      stateToChange: null,
      orders: [
      
      ],
      selectedItems: [

      ],
      loading: true,
      data: [],
      search: "",
      stateFilter: "all",
      stockFilter: null,
      states: [
        'draft',
        'pending',
        'confirmed',
        'preparing',
        'prepared',
        'sended',
        'delivered',
        'canceled'

      ]
    };
  },
  mounted() {
    this.loadData();
    this.stateFilter = this.stateFilterInit;
    
    if(this.$route.query.stateFilterInit) {
      this.stateFilter = this.$route.query.stateFilterInit
    } 
    
    if(this.$route.query.stockFilter){
      this.stockFilter = this.$route.query.stockFilter
    }
    
    
  },
  beforeRouteLeave (to, from, next) {
    console.log(to,from)
    alert('hey')
    if(this.getSelectedItems().length >0) {
      if(confirm("¿Estas seguro? Tienes "+this.getSelectedItems().length+' pedidos seleccionandos'))
        next()
    }

  },
  methods: {
    getSelectedItems() {
      return  this.filteredData().filter((product) => {
          return product.selected;
        })
    },

    availableStates() {
      if(this.$store.state.auth.rol == 'seller') {
          return [
          'draft',
          'pending',
          'confirmed',
          'canceled'
        ]
      } else 
      return this.states
    },
    loadData() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .get(this.$store.state.baseUrl + "/api/products")
        .then((response) => {
          if (response.data.rc == 1) {
            this.data = response.data.data;
          }
        })
        .catch(() => {
          console.error("ERROR");
          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filteredData() {
      const search = this.search.toLowerCase();
      let data = this.data;
      
      if(this.stockFilter !== null) {
        data = data.filter((product) => {
          if(this.stockFilter == 'with_alerts') {
            return product.real_alerts.length > 0
          }
          if(this.stockFilter == 'pending_to_confirm') {
            return product.info_stocks.incoming.length > 0
          }
          if(this.stockFilter == 'no_existences') {
            return product.info_stocks.available.length == 0
          }
        })
      }

      if(this.seller_id) {
        data = data.filter((product) => {
          
          return product.user_id == this.seller_id
        });
      }

      if (search && search.length > 0) {
        data = data.filter((product) => {
          const sku = (product.sku || "").toLowerCase();
          const productName = (product.name || "").toLowerCase();

          return sku.includes(search) || productName.includes(search);
        });
      }

      return data;
    },


    translate(state){
      switch (state) {
        case "draft":
            return "Borrador";
        case "pending":
            return "Pendiente";
        case "confirmed":
            return "Confirmado";
        case "preparing":
            return "En preparación";
        case "prepared":
            return "Preparado";
        case "sended":
            return "Enviado";
        case "delivered":
            return "Entregado";
        case "canceled":
            return "Anulado";
        default:
            return "";
    }

    },
    insertDecimal(number) {
      const numberString = number.toString();
      const decimalPosition = numberString.length - 2;
      const result = `${numberString.slice(0, decimalPosition)}.${numberString.slice(decimalPosition)}`;
      return parseFloat(result);
    },
    updateOrdersStatus() {
      console.log('updateOrdersStatus')

      // recorremos cada order y le aplicamos el cambio de order
      this.getSelectedItems().forEach(selectedproduct => {
        console.log(selectedproduct)
        var index = this.data.findIndex(product => product.id === selectedproduct.id);
        // this.filteredData[index].loadingNewState = true
        this.data[index].loadingNewState = true
        this.data[index].selected = false
        this.saveToServerOrder(this.data[index],index)

      });
    },
    saveToServerOrder(product,index) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .post(this.$store.state.baseUrl + "/api/order/"+product.id+"/edit",{
          state: product.state
        })
        .then((response) => {
          if (response.data.rc == 1) {
            this.data[index] = response.data.data;
          }
        })
        .catch(() => {
          console.error("ERROR");
          
        })
       
    }
  },
};
</script>
