<template>
    <div class="container">
      <Table />
    </div>
  </template>
  
  <script>
  import Table from "@/views/notifications/table.vue";
  export default {
    name: "Notifications",
    components: {
      Table,
    },
  };
  </script>
  