<template>
  <div>
    <div class="d-flex justify-content-between">
      
      <div class="d-flex w-80">
        <p class="w-30" v-html="$store.state.dateFormat(data.created_at)"></p>
        <div class="w-70">
          <b v-if="data.user">A {{data.user.name}} - {{ data.user.email }}: </b> 
        </div>
      </div>
      <div @click="open = !open" class="">
        <i v-if="!open"  class="bi bi-caret-down-fill"></i>
        <i v-if="open"  class="bi bi-caret-up-fill"></i>
      </div> 
    </div>
    <div v-if="open">
      <div class="w-100">
        <b>Título: </b> {{data.title}}
      </div>
      <div class="w-100">
        <b>Subtítulo: </b> {{data.subtitle}}
      </div>
      <div class="w-100">
        <b>Mensaje: </b> {{ data.description }}
      </div>
    </div>
  </div>
  
</template>

<script>



export default {
  components: {
  }, 
  data() {
    return {
      open:false
    };
    
  },
  props: {
    data: {
      required: true
    }
  },
  methods: {
    
  }


  
  
};
</script>
