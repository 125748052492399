<template>
  <div>

    <div  @click="open = true" class="d-flex pointer">
      <div class="icon-button-circle">
        <i class="bi bi-plus-circle-fill"></i>  
      </div>
    </div>
    

    <div v-if="open" class="fullScreenBlack">
      <div class="card p-4">
        <!--  -->

        <div class="d-flex justify-content-between">
          <h4>Añadir productos</h4>
          <div v-if="selections.length>0">
            <button @click="this.open = false"   type="button" class="m-0 btn btn-secondary me-3">Cancelar </button>

            <button @click="emitSelecteds()"  type="button" class="m-0 btn btn-primary ps-5 pe-5">Añadir {{ selections.length }} productos</button>

          </div>

          <i v-else @click="open = false" class="bi bi-x-lg pointer"></i>
        </div>


        <div class="form-group">
          <label for="search">Buscar</label>
          <input v-model="search" type="text" class="form-control" id="search" aria-describedby="emailHelp" name="search" placeholder="Buscar">
        </div>

        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">sku</th>
              <th scope="col">Nombre</th>
              <!-- <th scope="col">Coste unidad</th> -->
              <th scope="col">PVP unidad</th>
              <th scope="col">Añadir</th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="product in this.filteredData()" :key="product.id" :class="{'opacity-second' : this.selectionsIn.find(select => select.product_id === product.id)}">
              <th scope="row">{{ product.sku }}</th>
              <td>
                <ProductInfo :data="product"/>
              </td>
              <td>{{product.real_client_price}} €</td>
              <td>
                <AddSelection :product="product" @selected="addSelection"/>
              </td>

              
            </tr>
            
          </tbody>
        </table>


        <!-- <div  class="infoSelect selectable p-2 pointer" @click="selectUser(user)">
          <h1>{{product.name}}</h1>
        </div> -->
        <h5 v-if="this.filteredData().length == 0">Parece que no hay resultados </h5>
      
      
      <!--  -->
      </div>
    </div>

    


  </div>
</template>

<script>

import ProductInfo from "@/views/components/ProductInfo.vue";
import AddSelection from "@/views/components/addSelection.vue";

export default {
  components: {
    ProductInfo,
    AddSelection
  }, 
  props: {
    selectionsIn: {
      required: false,
      default: []
    }
  },
  data() {
    return {
      data: [],
      open: false,
      search: "",
      selections: [

      ]
      
    };
    
  },
  created () {
    this.data = this.users
  },  
  methods: {
    emitSelecteds(){
      this.$emit('selecteds',this.selections)
      this.selections = []
      this.open = false
    },
    addSelection(selectIn) {
      this.selections
      const encontrado = this.selections.find(select => select.product_id === selectIn.product_id);
      if (encontrado) {
        const indice = this.selections.findIndex(select => select.product_id === selectIn.product_id);

        // Si el ID está en la lista, reemplazar el objeto existente con el nuevo objeto
        this.selections[indice] = selectIn;
      } else {
        // Si el ID no está en la lista, simplemente hacer un push del nuevo objeto
        this.selections.push(selectIn);
      }
      // si es 0 se quita
      this.selections = this.selections.filter(objeto => objeto.quantity >0);
    },
    selectUser(user){
      this.$emit('selected',user)
      this.search = ""
      this.open = false

    },
    filteredData() {
      const search = this.search.toLowerCase();
      var data = this.$store.state.init.products

      if (search && search.length > 0) {
        data = data.filter((product) => {
          const name = (product.name || '').toLowerCase();
          const sku = (product.sku || '').toLowerCase();


          return (
            name.includes(search) ||
            sku.includes(search)           
            );
        });
      }
      return data;
    },
    
  }
};
</script>
