<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs v-if="false" :currentPage="currentRouteName" :textWhite="textWhite" />
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
        </div>
        <ul class="navbar-nav justify-content-end">
          <li v-if="this.$store.state.auth == false" class="nav-item d-flex align-items-center">
            <router-link
              :to="{ name: 'Sign In' }"
              class="px-0 nav-link font-weight-bold"
              :class="textWhite ? textWhite : 'text-body'"
            >
              <i
                class="fa fa-user"
                :class="this.$store.state.isRTL ? 'ms-sm-2' : 'me-sm-1'"
              ></i>

              <span class="d-sm-inline d-none">Login</span>
            </router-link>
          </li>
          <li v-else class="nav-item d-flex align-items-center">
            <router-link
              :to="{ name: 'Profile' }"
              class="px-0 nav-link font-weight-bold"
              :class="textWhite ? textWhite : 'text-body'"
            >
              <i
                class="fa fa-user"
                :class="this.$store.state.isRTL ? 'ms-sm-2' : 'me-sm-1'"
              ></i>

              <span class="d-sm-inline d-none"> {{ this.$store.state.auth.name }} </span>
            </router-link>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-body"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>

          <li
            class="nav-item dropdown d-flex align-items-center m-3"
            :class="this.$store.state.isRTL ? 'ps-2' : 'pe-2'"
          >
          <router-link
            :to="{
              name: 'Notifications', 
            }"
          >
            <a
              href="#"
              class="p-0 nav-link"
              :class="[textWhite ? textWhite : 'text-body', showMenu ? 'show' : '']"
            >
              <i class="cursor-pointer fa fa-bell"></i>
            </a>
          </router-link>
            <!-- {{ this.data[0] }} --> 
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              aria-labelledby="dropdownMenuButton"
            >
              <li v-for="(item, index) in this.data" :key="index" class="mb-2">
                <router-link 
                    :to="item.link" 
                class="dropdown-item border-radius-md">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">{{ item.title }}</span>
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        {{ minutesAgo(item.created_at) }} 
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <router-link
            :to="{
              name: 'Notifications', 
            }"
          >
          <button class="btn btn-secondary p-2 m-0 ">ver todos </button> 

        </router-link>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      data: [],
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      setTimeout(() => {
    console.log(this.$store.state);
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.$store.state.token;
    axios
      .get(this.$store.state.baseUrl + "/api/user/notifications?limit=5")
      .then((response) => {
        if (response.data.rc == 1) {
          this.data = response.data.data;
        }
      })
      .catch(() => {
        console.error("ERROR");
      })
      .finally(() => {
        this.loading = false;
      });
  }, 1);
    },
     minutesAgo(created_at) {
      const createdAtTime = new Date(created_at);
  const currentTime = new Date();
  const timeDifference = currentTime.getTime() - createdAtTime.getTime();
  const minutesAgo = Math.floor(timeDifference / (1000 * 60));

  if (minutesAgo < 60) {
    return minutesAgo + " minutes ago";
  } else {
    const hours = Math.floor(minutesAgo / 60);
    const remainingMinutes = minutesAgo % 60;
    return hours + " hours " + remainingMinutes + " minutes ago";
  }
},
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  updated() {
    // const navbar = document.getElementById("navbarBlur");
    // window.addEventListener("scroll", () => {
    // if (window.scrollY > 10 && this.$store.state.isNavFixed) {
    //   navbar.classList.add("blur");
    //   navbar.classList.add("position-sticky");
    //   navbar.classList.add("shadow-blur");
    // } else {
    //   navbar.classList.remove("blur");
    //   navbar.classList.remove("position-sticky");
    //   navbar.classList.remove("shadow-blur");
    // }
    // });
  },
};
</script>
