<template>
  <div class="container">
    <h3>Pedidos</h3>
    <Table :padding="2" :showFilter="true" />
  </div>
</template>

<script>
import Table from "@/views/orders/table.vue";
export default {
  name: "orders",
  components: {
    Table,
  },
};
</script>
