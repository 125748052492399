<template>
  <div>

    <div  @click="open = true" class="d-flex pointer">
      <div class="circle-button">
        <i class="bi bi-pencil-fill"></i>
      </div>
    </div>
    

    <div v-if="open" class="fullScreenBlack">
      <div class="card p-4">
        <!--  -->

        <div class="d-flex justify-content-between">
          <h4>Selecciona un usuario</h4>
          <i @click="open = false" class="bi bi-x-lg pointer"></i>
        </div>

        <div class="form-group">
          <label for="search">Buscar</label>
          <input v-model="search" type="text" class="form-control" id="search" aria-describedby="emailHelp" name="search" placeholder="Buscar">
        </div>


        <div v-for="user in this.filteredData()" :key="user.id" class="infoSelect selectable p-2 pointer" @click="selectUser(user)">
          <ClientInfo :data="user" class=" " />
        </div>
        <h5 v-if="this.filteredData().length == 0">Parece que no hay resultados </h5>
      
      
      <!--  -->
      </div>
    </div>

    


  </div>
</template>

<script>



import ClientInfo from "@/views/components/ClientInfo.vue";

export default {
  components: {
    ClientInfo
  }, 
  props: {
    users: {
      default: []
    }
  },
  data() {
    return {
      data: [],
      open: false,
      search: ""
      
    };
    
  },
  created () {
    this.data = this.users
  },  
  methods: {
    selectUser(user){
      this.$emit('selected',user)
      this.search = ""
      this.open = false

    },
    filteredData() {
      const search = this.search.toLowerCase();
      var data = this.data;

      if (search && search.length > 0) {
        data = data.filter((user) => {
          // client
          const name = (user.name || '').toLowerCase();
          const email = (user.email || '').toLowerCase();


          return (
            email.includes(search) ||
            name.includes(search)           
            );
        });
      }
      return data;
    },
    
  }
};
</script>
