<template>
  <div class="card p-4">
   
    <div class="fw-bold text-lg d-flex justify-content-between">Equivalencias
    <soft-switch
      v-if="enableOptions"
      class="ps-0 "
      :name="'option'"
      label-class="mb-0 text-body ms-3  w-80"
      :value="wantEquivalences"
      @valor="this.wantEquivalences = $event"
      ></soft-switch>
    </div>
    <table v-if="wantEquivalences" class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Tipo</th>
          <th  class="text-right">Unidades</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="m-auto p-1">
              1 Caja <i class="bi bi-box-seam-fill"></i>
            </div>
          </td>
          <td class="text-right">
            <input :readonly="!editable" required v-model="this.value" @change="this.$emit('input-change', 'equivalence_box_to_units', $event.target.value)" step="1" min="1" class="form-control w-80 ms-auto text-right" type="number" placeholder="Unidades">
          </td>
        </tr>

      </tbody>
    </table>
    <small v-if="wantEquivalences">Trabaja con diferentes tipos de stock <a target="_blank" href="http://poropo.es">Más información</a></small>
    <div class="d-flex mt-3" v-if="!enableOptions && this.value == null">
      <i class="bi bi-gear h2 me-3"></i> 
      <p class="m-auto ms-2">Configuralo desde 
        <router-link 
        class="btn btn-link p-0 m-0"
        :to="{
          name:'EditProduct',
          params: {
            product_id: data.id
          }
        }"
        > editar producto
      </router-link> en <i> Equivalencias</i>
      </p>
    </div>


    

  </div>
</template>

<script>

import SoftSwitch from "@/components/SoftSwitch.vue";

export default {
  props: {
    data: {
      required: true,
    },
    enableOptions: {
      required: true
    },
    editable: {
      default: true
    }


  },
  components: {
    SoftSwitch
  },
  created() {
    if(this.data.equivalence_box_to_units !== null)
      this.wantEquivalences = true
      this.value = this.data.equivalence_box_to_units
      
  },
  data() {
    return {
      wantEquivalences: false,
      value: null
    };
  },
};
</script>
