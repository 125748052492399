<template>
  <div class="container p-0 mt-4">
    <sidenav
      :custom_class="$store.state.mcolor"
      :class="[
        $store.state.isTransparent,
        $store.state.isRTL ? 'fixed-end' : 'fixed-start',
      ]"
      v-if="$store.state.showSidenav"
    />

    <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <navbar
        :class="navClasses"
        :textWhite="$store.state.isAbsolute ? 'text-white opacity-8' : ''"
        :minNav="navbarMinimize"
        v-if="$store.state.showNavbar"
      />

      <div v-if="$store.state.loading" class="d-flex justify-content-center full-screen">
        <Cargador :width="170" />
      </div>
      <div v-if="$store.state.error" class="d-flex justify-content-center full-screen">
        <h4 class="m-auto">
          <Cargador :width="170" :fontSize="false" />
          Parece que ha habido algún problema
        </h4>
      </div>
      <router-view v-if="!this.$store.state.loading" :key="$route.path" class="main-view mb-5" />
      <app-footer v-show="$store.state.showFooter" />
    </main>
  </div>
</template>

<script>
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";
import cookie from 'cookiejs';
import axios from 'axios';

export default {
  name: "App",

  components: {
    Sidenav,
    Navbar,
    AppFooter,
  },

  created() {
    this.checkToken();
  },

  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),

    checkToken() {
      const authToken = cookie.get('authToken');
      if (!authToken && this.$route.name !== "Sign In" && this.$route.name !== 'New Password') {
        if (this.$route.name == 'New Password') {
          this.$store.state.loading = false;
        }
      }
      this.validateToken(authToken);
    },

    // this is called when you have been logged and everythink is okay
    initApp() {

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.token;
      axios.get(this.$store.state.baseUrl + '/api/init')
        .then(response => {
          if (response.data.rc === 1) 
            this.$store.state.init = response.data.data;
          else
            this.$store.state.error = true
            
          
        })
        .catch(() => {
          this.$store.state.error = true
        })
        .finally(() => {
          this.$store.state.loading = false;
        });
    },

    validateToken(token) {
      this.$store.state.token = token;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios.get(this.$store.state.baseUrl + '/api/auth')
        .then(response => {
          if (response.data.rc === 1) {
            this.$store.state.auth = response.data.data;
            this.initApp();
            
          } else {
            // if (this.$route.name !== 'New Password') {
            //   this.$router.push({ name: 'Sign In' });
            // }
            this.$store.state.loading = false;

          }
        })
        .catch(() => {
          console.error('TOKEN ERROR');
          if (this.$route.name !== 'New Password') {
            this.$router.push({ name: 'Sign In' });
          }
          this.$store.state.loading = false;

        })
        .finally(() => {
        });
    }
  },

  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this.$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state.isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },

  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
};
</script>
