<template>
  <div class="card p-4" :class="{'h-100' : this.h100}">

    <router-link 
    v-if="data.id"
      :to="{
        name: (data.rol == 'seller')? 'ViewVendor' : 'ViewClient',
        params: {
          client_id: data.id,
          seller_id: data.id
        }
      }"
     class="imageCardAndNamme">
      <div  class="ImageFloating">
        <img
          v-if="data.image_url"
          :src="data.image_url"
          class="shadow-sm border-radius-lg"
        />
        <img
          v-else
          :src="'https://ui-avatars.com/api?name=' + (data.name || '') + '&background=8392AB&color=fff&size=50'"
          class="clientInfo-img shadow-sm border-radius-lg"
        />
      </div>
     
    </router-link>
    <div v-else class="imageCardAndNamme">
      <div class="ImageFloating">
        <img
          src="https://ui-avatars.com/api?name=&background=8392AB&color=fff&size=50"
          class="clientInfo-img shadow-sm border-radius-lg"
        />
      </div>
    </div>
    
      <div class="fw-bold text-lg">{{ title }}</div>

    <div v-for="field in fields" :key="field.label">
      <div class="row" v-if="data && data[field.key] !== null && data[field.key] !== undefined">
        <label :for="field.id" class="col col-form-label">{{ field.label }}</label>
        <div class="col m-auto">
          <input
            type="text"
            readonly
            class="form-control-plaintext"
            :id="field.id"
            :value="data[field.key]"
          />
        </div>
      </div>
      
    </div>

    <div v-if="filter">
      <SelectUser @selected="$emit('selected', $event)" :users="$store.state.init[filter]" />
    </div>

  </div>
</template>

<script>
import SelectUser from "@/views/components/SelectUser.vue";

export default {
  props: {
    data: {
      default: null
    },
    title: {
      default: "Usuario",
    },
    filter: {
      default: null,
    },
    h100: {
      default: false
    }
  },
  components: {
    SelectUser
  },
  data() {
    return {
      fields: [
        { label: "Nombre", id: "staticName", key: "name" },
        { label: "DNI/NIF", id: "staticDniNif", key: "nif" },
        { label: "Teléfono", id: "staticTelephone", key: "telephone" },
        { label: "Correo", id: "staticEmail", key: "email" },
      ],
    };
  },
};
</script>
