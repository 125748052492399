<template>
  <div class="text-left">
    <div v-if="!loading && !restored" style="text-align: left;" @click="this.restore()" class="btn btn-link w-100 text-left m-0 p-0 link_grey">
      Restablecer Contraseña.
    </div>
    <div v-if="loading" class="spinner-border" rol="status"></div>
    <div v-if="restored" class="alert alert-success">Se ha enviado un correo para restaurar la contraseña correctamente</div>
    <small class="w-100">Se enviará un correo a {{ userIn.email }}</small>
    
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    userIn: {
      default: false
    }

  },
  data() {
    return {
      loading: false,
      restored: false,
    }
  },
  methods: {
    restore(){
      this.loading = true;

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .post(this.$store.state.baseUrl + "/api/user/forget",{
          email: this.userIn.email?? this.$store.state.auth.email,
        })
        .then((response) => {
          if (response.data.rc == 1) {
            this.data = response.data.data;
            this.restored = true;
          }
        })
        .catch(() => {
          this.error = true
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};


</script>