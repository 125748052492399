<template>
  <div class="card p-4">
    <div class="fw-bold text-lg">Detalles del Producto</div>
    <div class="row mt-2">
      <div class="col-md-6" :class="{'col-md-12': editable }">

        

        <div v-if="data.deleted_at" class="row">
          <label class="col-sm-4 col-form-label text-danger">Eliminado</label>
          <div class="col-sm-8 m-auto">
            <p class="d-flex align-items-center justify-content-end m-auto text-danger" v-html="this.$store.state.dateFormat(data.deleted_at)" @change="this.$emit('input-change', 'created_at', $event.target.value)"></p>
          </div>
        </div>

        <div v-if="data.created_at" class="row">
          <label class="col-sm-4 col-form-label">Creación</label>
          <div class="col-sm-8 m-auto">
            <p class="d-flex align-items-center justify-content-end m-auto" v-html="this.$store.state.dateFormat(data.created_at)" @change="this.$emit('input-change', 'created_at', $event.target.value)"></p>
          </div>
        </div>

        <div class="row">
          <label for="name" class="col-sm-4 col-form-label">Nombre</label>
          <div class="col-sm-8 m-auto">
            <input
              required
              type="text"
              :class="editable ? 'form-control form-smaller' : 'form-smaller form-control-plaintext'"
              :readonly="!editable"
              :value="data.name"
              @change="this.$emit('input-change', 'name', $event.target.value)"
            />
          </div>
        </div>
        <div class="row">
          <label for="sku" class="col-sm-4 col-form-label">SKU</label>
          <div class="col-sm-8 m-auto">
            <input
              required
              type="text"
              :class="editable ? 'form-control form-smaller' : 'form-smaller form-control-plaintext'"
              :readonly="!editable"
              :value="data.sku"
              @change="this.$emit('input-change', 'sku', $event.target.value)"
            />
          </div>
        </div>
        <div class="row">
          <label for="sku" class="col-sm-4 col-form-label">EAN</label>
          <div class="col-sm-8 m-auto">
            <input
              required
              type="text"
              :class="editable ? 'form-control form-smaller' : 'form-smaller form-control-plaintext'"
              :readonly="!editable"
              :value="data.custom_id"
              @change="this.$emit('input-change', 'custom_id', $event.target.value)"
            />
          </div>
        </div>
        <div class="row">
          <label for="subtitle" class="col-sm-4 col-form-label">Subtítulo</label>
          <div class="col-sm-8 m-auto">
            <input
              type="text"
              :class="editable ? 'form-control form-smaller' : 'form-smaller form-control-plaintext'"
              :readonly="!editable"
              :value="data.subtitle"
              @change="this.$emit('input-change', 'subtitle', $event.target.value)"
            />
          </div>
        </div>
        <div class="row">
          <label for="description" class="col-sm-4 col-form-label">Descripción</label>
          <div class="col-sm-8 m-auto">
            <textarea
              class="form-control form-smaller mt-1"
              v-model="data.description"
              :readonly="!editable"
              rows="3"
              @change="this.$emit('input-change', 'description', $event.target.value)"
            ></textarea>
          </div>
        </div>
        <div class="row">
          <label for="client_price" class="col-sm-4 col-form-label">PVP €</label>
          <div class="col-sm-8 m-auto">
            <input
              type="number"
              step="0.01"
              required
              :class="editable ? 'form-control form-smaller' : 'form-smaller form-control-plaintext'"
              :readonly="!editable"
              class="text-sm-left text-right"
              :value="data.real_client_price"
              @change="this.$emit('input-change', 'real_client_price', $event.target.value)"
            />
          </div>
        </div>
        <div class="row">
          <label for="real_seller_price" class="col-sm-4 col-form-label">Coste €</label>
          <div class="col-sm-8 m-auto">
            <input
              type="number"
              step="0.01"
              required
              :class="editable ? 'form-control form-smaller' : 'form-smaller form-control-plaintext'"
              :readonly="!editable"
              class="text-sm-left text-right"
              :value="data.real_seller_price"
              @change="this.$emit('input-change', 'real_seller_price', $event.target.value)"
            />
          </div>
        </div>
        
        <div v-if="false" class="row">
          <label for="equivalence_box_to_units" class="col-sm-4 col-form-label">Equivalencia en Unidades</label>
          <div class="col-sm-8 m-auto">
            <input
              type="number"
              step="1"
              required
              :class="editable ? 'form-control form-smaller' : 'form-smaller form-control-plaintext'"
              :readonly="!editable"
              class="text-sm-left text-right"
              :value="data.equivalence_box_to_units"
              @change="this.$emit('input-change', 'equivalence_box_to_units', $event.target.value)"
            />
          </div>
        </div>

        
      </div>
      <div v-if="data.id" class="col-md-6" :class="{'col-md-12': editable }">
        <div class="row mt-2">
          <label class="col-sm-6 col-form-label">Imagen</label>
          <div class="col-sm-6">
            <img class="product-img-2 w-100" v-if="data.image_url" :src="data.image_url" alt="">
            <img v-else class="product-img-2 w-100" :src="this.$store.state.baseUrl+'/products/default.png'" alt="" />
            <input type="file" ref="imageInput" class="d-none" @change="handleImageChange" accept="image/*">
            <button v-if="!loading" type="button" class="btn btn-link p-0 mt-1 w-100 text-right" @click="openImageSelector">Cambiar Imagen</button>
            <Cargador v-else/>
            <div v-if="error" class="text-danger">
              Parece que ha habido un problema - {{ this.error }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    info: {
      required: true
    },
    editable: {
      default: false
    }
  },
  data() {
    return {
      data: null,
      loading: false,
      error: false
    };
  },
  created() {
    this.data = this.info;
  },
  methods: {
    openImageSelector() {
      this.$refs.imageInput.click();
    },
    handleImageChange(event) {
      if(this.loading)
        return;
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.data.image_url = reader.result;
        };
        reader.readAsDataURL(file);

        const formData = new FormData();
        
        
        this.loading = true
        formData.append('image', file);

        this.loading = true;
        this.error = false

        // Realizar la solicitud POST con Axios
        
        
        axios
        .post(this.$store.state.baseUrl + `/api/product/edit/${this.$route.params.product_id}`,formData,{
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+this.$store.state.token
          }
        })

        .then(response => {
          if (response.data.rc !== 1) {
            this.error = response.data.data

          } 
        })
        .catch(error => {
          // Manejar errores de la solicitud si es necesario
          this.error = error
        })
        .finally(() => {
          this.loading = false;
        });
      }
    }
  }
};
</script>