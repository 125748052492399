<template>
  <div >
    <div class="container mt-4" v-if="!this.loading && !error">

    <!-- P y E -->
     <div class="row ">
      <div class="d-flex justify-content-between">
        <div>
            <div class="d-flex align-items-center">
              <div class="h3 p-0 m-auto me-3">Producto</div>  #{{ this.data.sku }}
            </div>
            <router-link
              class="back-button"
                :to="{
                  name: 'Products'
                 
                }"
                > 
              <i class="bi bi-arrow-left "></i> Productos
            </router-link>

          </div>
        <router-link
          class="btn btn-primary ps-7 pe-7 mt-auto"
          v-if="!this.data.deleted_at"
          :to="{
            name: 'EditProduct',
            params: {
              product_id: this.data.id
            }
          }"> 
          <i class="bi bi-pencil-fill me-1"></i> Editar
        </router-link>
      </div>
     </div>

     <div class="row mt-3">
      <div  class="col-md-12">
        <DetailsProduct  :info="data"/>

      </div>
     </div>

     <div class="row ">
      <div class="col-md-4 mt-4" >
        <ClientCard :h100="true"   v-if="$store.state.auth.rol == 'worker' && data.seller" :data="data.seller" :title="'Propietario (Vendedor)'" />
      </div>
      <div  class="col-md-8 mt-4" :class="{'col-md-12' : this.$store.state.auth.rol == 'seller'}">
        <EquivalenceTable :enableOptions="false" :editable="false" :data="data"/>
      </div>

     </div>

     <div class="row ">
      <!-- Detalles del pedido -->
      <div class="col-md-8 mt-4">
        <Stocks :editable="true"  :productIn="data"/>
      </div>
      <!-- Detalles del pedido -->
      <div class="col-md-4 mt-4">
        <Alerts :productIn="data"/>
      </div>
     </div>

     <div class="row">
        <div class="col-md-12">
          <HistoricCard class="mt-4 mb-5" :data="data"/>
        </div>
     </div>
     
        

       



        
       
    </div>
    <div v-if="loading" class="d-flex justify-content-center ">
      <Cargador/>
    </div> 
    <div v-if="!loading && error" class="alert alert-danger">
      <div v-if="error">
        {{ error }}
      </div>
    </div> 
  </div>
</template>

<script>
import DetailsProduct from "@/views/products/detailsTable.vue";
import EquivalenceTable from "@/views/products/EquivalenceTable.vue";
import Stocks from "@/views/products/Stocks.vue";
import Alerts from "@/views/products/AlertsStock.vue";

import ClientCard from "@/views/orders/order/ClientCard.vue";
import HistoricCard from "@/views/orders/order/HistoricCard.vue";



import axios from "axios";

export default {
  components: {
    DetailsProduct,
    ClientCard,
    HistoricCard,
    EquivalenceTable,
    Stocks,
    Alerts
  }, 
  data() {
    return {
      loading: true,
      error: false,
      data: [],
      order: {
        
      },
      
    };
    
  },
  mounted() {
    this.loadData(); 
  },
  methods: {
    loadData() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .get(this.$store.state.baseUrl + `/api/products/${this.$route.params.product_id}`)
        .then((response) => {
          if (response.data.rc == 1) {
            this.data = response.data.data;
          }
        })
        .catch(() => {
          this.error = "Error"
          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    
  }
};
</script>
