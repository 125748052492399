<template>
  <div class="container">
    <h3>Vendedores</h3>
    <Table :showFilter="true" />
  </div>
</template>

<script>
import Table from "@/views/vendors/table.vue";
export default {
  name: "Products",
  components: {
    Table,
  },
};
</script>
