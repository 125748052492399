<template>
  <div class="table-responsive p-0">
    <div v-if="error" class="alert alert-danger">
      Parece que hay algún error
    </div>
    <router-link
      v-if="this.$store.state.auth.rol == 'worker'"
        :to="{
          name: 'uploadFile',
          params: {
            user_id: user_id,
          },
        }"
      >
      <button class="btn btn-secondary">
        Subir Archivo
      </button>
    </router-link>

    <table v-if="!loading" class="table mb-0">
      <thead>
        <tr>
          <th>Título</th>
          <th>Fecha</th>
          <th>Opciones</th>
        </tr>
        
      </thead>
      <tbody v-if="files.length>0">
         <tr v-for="file in this.files" :key="file.id">
          <td>
            <div>
              <div class="text-sm">{{ file.title }}</div>
              <span v-if="file.details" class="text-xs subtitleFile"> {{file.details.slice(0, 10)}}</span>
            </div>
          </td>
          <td class="text-sm">
            <p v-html="this.$store.state.dateFormat(file.created_at)"></p>
          </td>
          <td>
            <button @click="downloadFile(file)" v-if="!file.downloading" class="btn btn-link">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
            </svg>
            </button>
            <div v-else class="spinner-border" rol="status"></div>

          </td>
          
         </tr>
        </tbody>
        <h4 class="mt-3" v-else>No hay resultados</h4>

    </table>
    <div v-else class="d-flex justify-content-center">
      <div class="spinner-border" rol="status"></div>
    </div> 
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: "files",
  props: {
    user_id:{
      default: null
    }
  },
  data() {
    return {
      files: [],
      loading:true,
      error: false


    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    downloadFile(file) {
      file.downloading = true;
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .get(this.$store.state.baseUrl+'/api/file/'+file.id+'/download')
        .then((response) => {
          if (response.data.rc == 1) {
            window.open(response.data.data, '_blank');
          }
        })
        .catch(() => {
          console.error("ERROR");
          this.error =true;
        })
        .finally(() => {
          file.downloading = false;
        });
    },
    getData() {
      var user_id = this.user_id?? this.$store.state.auth.id
      if(!user_id)
        return;
      // alert(user_id)
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .get(this.$store.state.baseUrl+'/api/user/'+user_id+'/files')
        .then((response) => {
          if (response.data.rc == 1) {
            this.files = response.data.data;
          }
        })
        .catch(() => {
          console.error("ERROR");
          this.error =true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>