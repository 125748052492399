<template>
  <div v-if="!this.loading">
    <div class="row">
      <div class="col-md-6">
        <h6>Notificaciones</h6>
      </div>
      <div v-if="false" class="col-md-6 d-flex justify-content-end">
        <!-- FILTRO -->
        <div v-if="this.showFilter" class="form-group w-30 m-2">
          <label for="exampleFormControlSelect1">Stocks</label>
          <select class="form-control" id="exampleFormControlSelect1">
            <option>Todos</option>
            <option>De Hoy</option>
            <option>Hace 3 Dias</option>
            <option>Hace una semana</option>
            <option>Hace un Mes</option>
          </select>
        </div>
        <!-- BUSCADOR -->
        <div v-if="this.showFilter" class="w-30 m-2">
          <label for="exampleFormControlSelect1">Buscar</label>
          <div class="input-group">
            <span class="input-group-text text-body"
              ><i class="fas fa-search" aria-hidden="true"></i
            ></span>
            <input
              v-model="this.search"
              type="text"
              class="form-control"
              :placeholder="'Buscar...'"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="d-flex justify-content-center mt-4">
      <div class="spinner-border" rol="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div v-else class="card mb-4">
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table mb-0">
            <thead>
              <tr>
                <th
                  class="col-sm-1 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                ></th>
                <th
                  class="col-sm-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  title
                </th>
                <th
                class="col-sm-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Subtitle
              </th>
                
     
                <th
                  class="col-sm-4 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7  "
                >
                  Descripción
                </th>
                <th
                  class="col-sm-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
                >
                  Creación
                </th>
                <th
                  class="col-sm-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody v-if="this.notificationsFiltered().length > 0">
              <tr v-for="notification in this.notificationsFiltered()" :key="notification.id">
                <td class="align-middle text-center">
                  <!-- {{ notification.isRead }} -->
                  <input v-if="notification.isRead" checked disabled type="checkbox"  />
                  <input v-else type="checkbox" @click.once="checkboxChanged(notification.id)" />
                </td>
                <td class="align-middle ">
                  <router-link
                    :to="notification.link"
                  >
                  {{ notification.title }}
                  </router-link>
                </td>
                <td class="align-middle ">
                  {{ notification.subtitle }}
                </td>
                <td class="col-sm-3 align-middle text-wrap text-xs">
                  <!-- descripcion breve -->
                  {{ notification.description }}
                </td>
                <td class="col-sm-2 align-middle text-start">
                  <!-- Fecha de creacion -->
                  <div class="d-flex flex-column align-left">
                    <p class="p-0 m-0 d-flex justify-content-center">
                      {{ this.convertDateTime(notification.created_at).date }}

                    </p>
                    <p class="p-0 m-0 text-xxs d-flex justify-content-center">
                      {{ this.convertDateTime(notification.created_at).time }}
                    </p>
                  </div>
                </td>
                
                <td class="col-sm-1 align-middle text-center">
                  <router-link
                    :to="notification.link"
                  >
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          opacity="0.4"
                          d="M15.48 3H7.52C4.07 3 2 5.06 2 8.52V16.47C2 19.94 4.07 22 7.52 22H15.47C18.93 22 20.99 19.94 20.99 16.48V8.52C21 5.06 18.93 3 15.48 3Z"
                          fill="#292D32"
                        ></path>
                        <path
                          d="M21.0195 2.97979C19.2295 1.17979 17.4795 1.13979 15.6395 2.97979L14.5095 4.09979C14.4095 4.19979 14.3795 4.33979 14.4195 4.46979C15.1195 6.91979 17.0795 8.87979 19.5295 9.57979C19.5595 9.58979 19.6095 9.58979 19.6395 9.58979C19.7395 9.58979 19.8395 9.54979 19.9095 9.47979L21.0195 8.35979C21.9295 7.44979 22.3795 6.57979 22.3795 5.68979C22.3795 4.78979 21.9295 3.89979 21.0195 2.97979Z"
                          fill="#292D32"
                        ></path>
                        <path
                          d="M17.8591 10.4198C17.5891 10.2898 17.3291 10.1598 17.0891 10.0098C16.8891 9.88984 16.6891 9.75984 16.4991 9.61984C16.3391 9.51984 16.1591 9.36984 15.9791 9.21984C15.9591 9.20984 15.8991 9.15984 15.8191 9.07984C15.5091 8.82984 15.1791 8.48984 14.8691 8.11984C14.8491 8.09984 14.7891 8.03984 14.7391 7.94984C14.6391 7.83984 14.4891 7.64984 14.3591 7.43984C14.2491 7.29984 14.1191 7.09984 13.9991 6.88984C13.8491 6.63984 13.7191 6.38984 13.5991 6.12984C13.4691 5.84984 13.3691 5.58984 13.2791 5.33984L7.89912 10.7198C7.54912 11.0698 7.20912 11.7298 7.13912 12.2198L6.70912 15.1998C6.61912 15.8298 6.78912 16.4198 7.17912 16.8098C7.50912 17.1398 7.95912 17.3098 8.45912 17.3098C8.56912 17.3098 8.67912 17.2998 8.78912 17.2898L11.7591 16.8698C12.2491 16.7998 12.9091 16.4698 13.2591 16.1098L18.6391 10.7298C18.3891 10.6498 18.1391 10.5398 17.8591 10.4198Z"
                          fill="#292D32"
                        ></path>
                      </g>
                    </svg>
                  </router-link>
                </td>
              </tr>
            </tbody>

            <div v-else class="">
              <h5 class="m-3 w-100">No hay resultados</h5>
              <img width="300" class="m-auto" :src="notFound" alt="" />
            </div>
          </table>
        </div>
      </div>
    </div>
    <!-- {{ this.notifications[0] }} -->
  </div>
    <div v-else class="d-flex justify-content-center">
      <div class="spinner-border" rol="status"></div>
    </div>  
</template>

<script>
import notFound from "@/assets/img/not-found.gif";
import axios from "axios";

export default {
  name: "notifications-table",
  
  props: {
    showFilter: {
      default: true,
    },
    canCreate: {
      default: true,
    },
  },
  data() {
    return {
      notFound,
      search: "",
      notifications: [],
      loading: true,
      data: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    checkboxChanged(id) {
    var url = "/api/user/notification/" + id + "/markread";
    console.log(url);

    axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .post(this.$store.state.baseUrl + url)
        .then((response) => {
          if (response.data.rc == 1) {
            // this.notifications = response.data.data;
            console.log(response.data.data);
          }
        })
        .catch(() => {
          console.error("ERROR");
          
        })
        .finally(() => {
          this.loading = false;
        });
    // Perform any action with the generated URL
  },
    loadData() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .get(this.$store.state.baseUrl + "/api/user/notifications")
        .then((response) => {
          if (response.data.rc == 1) {
            this.notifications = response.data.data;
          }
        })
        .catch(() => {
          console.error("ERROR");
          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // filtrado por nombre o descripción
    notificationsFiltered() {
      const search = this.search.toLowerCase();
      return this.notifications.filter((notification) => {
        const title = notification.title.toLowerCase();
        const desc = notification.description.toLowerCase();
        if (title.includes(search) || desc.includes(search)) return true;
        else return false;
      });
    },
    convertDateTime(dateTimeString) {
      const dateObj = new Date(dateTimeString);

      // Extracting date values
      const year = dateObj.getFullYear() % 100;
      const month = dateObj.getMonth() + 1; // Month is zero-based
      const day = dateObj.getDate();

      // Extracting time values
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();

      return {
        date: `${year}/${month.toString().padStart(2, "0")}/${day
          .toString()
          .padStart(2, "0")}`,
        time: `${hours}:${minutes.toString().padStart(2, "0")}`,
      };
    },
    
  },
};
</script>
