<template>
  <div>
    <div @click="open = !open" class="btn btn-secondary">
      <i class="bi bi-bell-fill"></i> +
    </div>
    <form v-if="open" class="floatingPop" @submit.prevent="this.addAlert" >
    <h5>Añadir Alerta</h5>
    <span>Cuando el Stock disponible sea menor a:</span>
    <div class="d-inline-flex mt-2">
      <div class="col-md-6 form-group m-0 mt-auto ms-1 col-md-5 d-flex">
          <input v-model="form.alert_on" required type="number" min="1" step="1" class="form-control" placeholder="Cantidad">
        </div>
        <div class="form-group m-0 mt-auto ms-1 col-md-6">
          <select
            class="form-control "
            id="quantityType"
            v-model="form.type"
          >
            <option
              :value="type.value"
              class="d-flex justify-content-center align-middle text-sm"
              v-for="(type, index) in this.getStockTypeOptions()"
              :key="index"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
        <div v-if="false" class=" form-group m-0 mt-auto ms-1 col-md-5 d-flex">
          <text class="m-auto">de  </text>
          <select
            class="form-control ms-1 "
            id="quantityType"
            v-model="form.state"
          >
            <option
              :value="type.value"
              class="d-flex justify-content-center align-middle text-sm"
              v-for="(type, index) in this.states()"
              :key="index"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
        
      </div>
      <div v-if="form.state == 'incoming'" class="col-md-12 p-3">
        <div class="input-group mt-2">
          <VueDatePicker :required="true" :placeholder="'Fecha llegada'" :enable-time-picker="false" v-model="form.incoming_date" />
        </div>
      </div>
      
      
      <div v-if="error" class="alert alert-danger mt-3">
        Vaya, parece que hay un error
      </div>

      <div>

      </div>
      <div class="w-100 mt-3">
        <button @click="close()" type="button" class="btn btn-secondary">
        Cancelar
      </button>
     
      <button @click="addAlert()" type="button" class="btn btn-primary ms-2">
        <div v-if="loading" class="spinner-border" rol="status"></div>
        <text v-else>Guardar</text>
      </button>
      </div>
      
    </form>
  </div>
  
</template>

<script>
import axios from "axios";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


export default {
  name: "addAlert",
  components: {
    VueDatePicker
  },
  props: {
    product_id: null,
    product: {
      required: true
    },
    productIn:{
      
    }

  },
  data() {
    return {
      loading: false,
      open: false,
      error:false,
      form: {
        alert_on: null,
        type: "unit",
        product_id: null
      },
      stockType: [
        {
          name: "Unidades",
          value: "unit"
        },
        {
          name: "Cajas",
          value: "box"
        },

        // }
      ],
      stockState: [
        {
          name: "Entrante",
          value: "incoming"
        },
        {
          name: "Borrador",
          value: "draft"
        },
        {
          name: "Pendiente",
          value: "pending"
        },
        {
          name: "Confirmado",
          value: "confirmed"
        },
        {
          name: "Preparación",
          value: "preparing"
        },
        {
          name: "Preparado",
          value: "prepared"
        },
        {
          name: "Enviado",
          value: "sended"
        },
        {
          name: "Entregado",
          value: "delivered"
        }
      ]
    }
  },
  created(){
    // si es vendedor solo incoming por defecto
    this.form.product_id = this.product.id
      


  },
  methods: {
    addAlert() {



      this.loading = true
      this.error = false
      this.form.product_id = this.product.id


      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .post(this.$store.state.baseUrl + "/api/alert",this.form)
        .then((response) => {
          if (response.data.rc == 1) {
            console.log('response',response.data.data)
            this.$emit("addAlert", response.data.data);
            this.open = false;
            this.form.alert_on = null
            this.form.type = 'unit'


          } else {
            this.error = true
          }
        })
        .catch((error) => {
          console.log(error)
          this.error = true;
          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.open = false
    },
    getStockTypeOptions() {
      if (this.product.equivalence_box_to_units === null) {
        // Si equivalence_box_to_units es nulo, solo mostrar 'Units'
        return [
          {
            name: "Unidades",
            value: "unit"
          }
        ];
      } else {
        // Si equivalence_box_to_units tiene un valor, mostrar 'Units' y 'Boxes'
        return [
          {
            name: "Unidades",
            value: "unit"
          },
          {
            name: "Cajas",
            value: "box"
          }
        ];
      }
    },
    states() {
      if(this.$store.state.auth.rol == 'worker')
        return this.stockState;
      return [
          {
            name: "Entrante",
            value: "incoming"
          }
      ]
    },
    
  }
};
</script>
