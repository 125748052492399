<template>
  <div class="card p-3">
    <div class="d-flex justify-content-between mb-2">
      <div class="fw-bold text-lg mb-1">Seguimiento <i class="bi bi-truck ms-2 h5"></i></div> 
      <div v-if="editable" @click="toggleOpen()" class="circle-button">
        <i v-if="form.delivery_number" class="bi bi-pencil-fill"></i>  
        <i v-else class="bi bi-plus-lg"></i>  

      </div>
    </div>

    <div v-if="!data.delivery_number">
      <span>No se ha asignado número de seguimiento </span> <i class="bi bi-clipboard2-x h6"></i> <br>
      <a v-if="editable" @click="toggleOpen()" href="javascript:void(0)">Añadir Num de seguimiento</a>
    </div>


    <!--  DATOS CERRADOS   -->
    <div v-else>
      <span  @click="copyToClipboard(data.delivery_number)" class=" w-100 hideenOverflowText" type="button"> 
        <svg width="17" class="svg-icon" style="width: 16px; height: 16px;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M704 230h180c6.6 0 12-5.4 12-12 0-16.4-7.4-32-20-42.6l-154.2-128.4c-9.8-8.2-28.4-14.8-41.2-14.8-8.2 0-14.8 6.6-14.8 14.8V192c0.2 21 17.2 38 38.2 38z" fill="" /><path d="M614 192V32H352c-35.2 0-64 28.8-64 64v672c0 35.2 28.8 64 64 64h480c35.2 0 64-28.8 64-64V282h-192c-49.6 0-90-40.4-90-90z" fill="" /><path d="M232 824V160H192c-35.2 0-64 28.8-64 64v704c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64v-40H296c-35.2 0-64-28.8-64-64z" fill="" /></svg>
        {{ data.delivery_number }}
      </span>
      <div v-if="data.pick_up_date" class="d-flex ">
        <span > <i class="bi bi-calendar2-event-fill me-1"></i>Recogida: </span>
        <span v-html="data.pick_up_date" class="w-50 ms-1 "></span>
      </div>
      <div v-if="data.delivery_date" class="d-flex ">
        <span ><i class="bi bi-calendar3-range-fill me-1"></i>Entrega: </span>
        <span v-html="data.delivery_date" class="w-50 ms-1"></span>
      </div>
      <span v-if="data.delivery_number" class="link w-100" type="button"> 
        <i class="bi bi-arrow-up-right-square-fill me-1"></i>
        <a target="_blank" :href="this.link()">Ver Seguimiento</a>
      </span>
      <span  @click="toggleOpen()" class="link w-100" type="button"> 
        <i class="bi bi-clipboard2-fill"></i>
        Ver detalles
      </span>
    </div>
    <!-- !DATOS CERRADOS -->

    <!-- open -->
    <div v-if="open" class="fullScreenBlack">
      <div class="card p-4">
        <div class="d-flex justify-content-between">
          <h4>Seguimiento</h4>
          <i @click="open = false" class="bi bi-x-lg pointer"></i>
        </div>

        <div class="mt-3">
          <div class="row text-center">
            <div v-for="carrier in $store.state.init.carriers" class="col-md-4" :key="carrier.unique_name" >
              <div  class="deliveryElement m-3" :class="{'deliveryElementSelected': form.deliver_name === carrier.unique_name}" @click="selectCarrier(carrier.unique_name)" >
                <div class="DeliveryImage">
                  <img class="" :src="carrier.logo"  alt="Transportista">
                </div>
                <span>{{carrier.name}}</span>
              </div>
            </div>
          </div>
          <form @submit.stop.prevent="this.sendData()">
            <div class="row mt-2">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="delivery_number">Número de Seguimiento</label>
                  <input :readonly="!editable" v-model="form.delivery_number" required type="text" class="form-control" id="delivery_number" aria-describedby="Número de Seguimiento" >
                </div>
              </div>
              
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="pick_up_date">Fecha de recogida</label>
                  <input :readonly="!editable" v-model="form.pick_up_date" type="date" class="form-control" id="pick_up_date" aria-describedby="Número de Seguimiento" >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="delivery_date">Fecha de entrega</label>
                  <input :readonly="!editable" v-model="form.delivery_date" type="date" class="form-control" id="delivery_date" aria-describedby="Número de Seguimiento" >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Notas</label>
                  <textarea :readonly="!editable" v-model="form.delivery_notes" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>
              </div>
            </div>
            <div  class="row mb-3 mt-1">
              <div v-if="editable" class="d-flex w-100 justify-content-end">
                <button type="button" @click="toggleOpen()" class="btn btn-secondary">Cancelar</button>
                <button type="submit" class="btn btn-primary ms-2 ">Guardar</button>
              </div>
              <div v-else class="d-flex w-100 justify-content-end">
                <button type="button" @click="toggleOpen()" class="btn btn-secondary">Cerrar sin Guardar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Object, 
      required: true 
    },
    editable: {
      required: true,
      default: false
    }
  },

  components: {

  },

  data() {
    return {
      clients: [],
      currentUser: null,
      loading: true,
      open: false,
      form: {
        pick_up_date: null,
        delivery_date: null,
        delivery_number: null,
        delivery_notes: null,
        deliver_name: null,

      }
      
    };
  },

  created() {
    console.log(this.data)
    this.form.pick_up_date = this.data.pick_up_date
    this.form.delivery_date = this.data.delivery_date
    this.form.delivery_number = this.data.delivery_number
    this.form.delivery_notes = this.data.delivery_notes
    this.form.deliver_name = this.data.deliver_name
    
    
  },

  methods: {
    link() {
      const carrier = this.data.deliver_name;
      const trackId = this.data.delivery_number;
      return `https://15track.com/update?carrier=${carrier}&trackid=${trackId}`;
    },
    toggleOpen() {
      this.open = !this.open
      
    },
    selectCarrier(carrier) {
      if(this.form.deliver_name == carrier){
        this.form.deliver_name = null
      } else {
        this.form.deliver_name = carrier
      }
    },
    sendData() {
      this.$emit('deliveryDataAdded',this.form)
      this.toggleOpen()
    },
    copyToClipboard(text) {
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert('Número de seguimiento copiado al portapapeles: ' + text);
    }

   
  }

 
  
};
</script>
