<template>
  <div class="product-card ">
    <img v-if="data.image_url" class="product-img" :src="data.image_url" alt="" />
    <img v-else class="product-img" :src="this.$store.state.baseUrl+'/products/default.png'" alt="" />

    <div class=" ms-2 product-info">
      <div class="text-left">
        <div class="fw-bold p-0  text-bold text-left">
          {{ data.name }}
        </div>
        <div class="fw-bold p-0  text-left" style="text-align: left;">#{{ data.sku}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data'],
  data() {
    return {
      loading: false,
    }},
};
</script>
