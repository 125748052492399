<template>
  <div v-if="!editable" :class="estado" class="estado-texto">
    {{ estadoTexto(currentState) }}
    <i v-if="estado == 'draft'" class="bi bi-pencil"></i>  
    <i v-if="estado == 'pending'" class="bi bi-hourglass"></i>  

    <i v-if="estado == 'confirmed'" class="bi bi-check2-square"></i>  
    <i v-if="estado == 'preparing'" class="bi bi-dropbox"></i>  
    <i v-if="estado == 'prepared'" class="bi bi-box-seam-fill"></i> 

    <i v-if="estado == 'sended'" class="bi bi-truck"></i>  
    <i v-if="estado == 'delivered'" class="bi bi-check"></i> 
    
    <i v-if="estado == 'canceled'" class="bi bi-x-lg"></i> 
 


  </div>

  <div v-else class="dropdown">
    <button @click="openCLose()"  class="btn dropdown-toggle"  :class="estadoClass(this.currentState)" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {{ estadoTexto(currentState) }}
      <i v-if="estado == 'draft'" class="bi bi-pencil"></i>  
      <i v-if="estado == 'pending'" class="bi bi-hourglass"></i>  

      <i v-if="estado == 'confirmed'" class="bi bi-check2-square"></i>  
      <i v-if="estado == 'preparing'" class="bi bi-dropbox"></i>  
      <i v-if="estado == 'prepared'" class="bi bi-box-seam-fill"></i> 

      <i v-if="estado == 'sended'" class="bi bi-truck"></i>  
      <i v-if="estado == 'delivered'" class="bi bi-check"></i> 
      
      <i v-if="estado == 'canceled'" class="bi bi-x-lg"></i>
    </button>
    <div class="dropdown-menu estadosMenu" :class="{ 'show': open }" aria-labelledby="dropdownMenuButton">
      <div v-for="state in this.states" :key="state">
        <a  @click="changeState(state)" v-if="statusCanBeEditedBySeller(state)" class="dropdown-item" :class="state" href="#">{{estadoTexto(state)}}
          <i v-if="state == 'draft'" class="bi bi-pencil"></i>  
          <i v-if="state == 'pending'" class="bi bi-hourglass"></i>  

          <i v-if="state == 'confirmed'" class="bi bi-check2-square"></i>  
          <i v-if="state == 'preparing'" class="bi bi-dropbox"></i>  
          <i v-if="state == 'prepared'" class="bi bi-box-seam-fill"></i> 

          <i v-if="state == 'sended'" class="bi bi-truck"></i>  
          <i v-if="state == 'delivered'" class="bi bi-check"></i>  

          <i v-if="estado == 'canceled'" class="bi bi-x-lg"></i> 

        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    estado: {
      type: String,
    },
    editable: {
      default: false
    }
  },
  data() {
    return {
      open :false,
      currentState : null,
      states: [
        'draft',
        'pending',
        'confirmed',
        'preparing',
        'prepared',
        'sended',
        'delivered',
        'canceled'

      ]
    }
  },
  mounted() {
    this.currentState = this.estado
  },
  methods: {
    
    estadoClass(state) {
      return state
      // switch (state) {
      //   case "draft":
      //     return "gray";
      //   case "pending":
      //     return "yellow-orange";
      //   case "confirmed":
      //     return "yellow-green";
      //   case "preparing":
      //     return "orange";
      //   case "prepared":
      //     return "orange-green";
      //   case "sended":
      //     return "green-blue";
      //   case "delivered":
      //     return "green-success";
      //   case "canceled":
      //     return "red";
      //   default:
      //     return "";
      // }
    },

    changeState(stateIn) {
      this.currentState = stateIn
      this.open = false
      this.$emit('statusUpdated',stateIn)
    },
    statusCanBeEditedBySeller(stateIn) {
      if(this.$store.state.auth.rol == 'seller') {
          switch (stateIn) {
          case "draft":
            return true;
          case "pending":
            return true;
          case "confirmed":
            return true;
          case "canceled":
            return true;
          default:
            return "";
        }
      } 
      return true;
      
    },
    estadoTexto(state) {
      switch (state) {
        case "draft":
          return "BORRADOR";
        case "pending":
          return "PENDIENTE";
        case "confirmed":
          return "CONFIRMADO";
        case "preparing":
          return "EN PREPARACIÓN";
        case "prepared":
          return "PREPARADO";
        case "sended":
          return "ENVIADO";
        case "delivered":
          return "ENTREGADO";
        case "canceled":
          return "ANULADO";
        default:
          return "";
      }
    },

    openCLose() {
      this.open = !this.open
    }
  },
};
</script>
