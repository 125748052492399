<template>
  <div  class="card p-3">
    <div class="d-flex justify-content-between">
      <div class="fw-bold text-lg mb-auto mt-auto">Alertas </div>

      <addAlert @addAlert="this.addAlert($event)" :product="this.product" :product_id="this.product.id" />
    </div>
    <hr />
    <div class=" table-responsive">
      <table class="table">
        <tbody  class="">
          <tr v-for="alert in productIn.alerts" :key="alert.id" class="d-flex" >
            <div class="alertStock text-center">
              <div class="w-100">&lt; {{ alert.alert_on }}</div>
              <div v-if="alert.type == 'unit'" class="w-100 text-sm">Unidades</div>
              <div v-if="alert.type == 'box'" class="w-100 text-sm">Cajas</div>
              
            </div>
            <div class="deleteAlert m-auto me-0 pointer" @click="deleteAlert(alert)">
                <i class="bi bi-x-circle "></i>
              </div>
            
          </tr>
            
          
        </tbody>
    </table>


    </div>
  
  </div>
</template>

<script>
import addAlert from "@/views/products/addAlert.vue";
import _ from 'lodash';
import axios from "axios";

export default {
  props: ['productIn'],
  components: {
    addAlert
  },
  data() {
    return {
      product: {
        stocks: []
      },
      founded: false,
      available: ['draft'], 
      reserved: 
        [
          'pending',
          'confirmed',
          'preparing',
          'prepared',
          'sended',
        ]


    }
  },
  mounted() {
    this.product = this.productIn
  },
  methods: {
    addAlert(alert) {
      this.product.alerts.push(alert)
    },
    deleteAlert(alert) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .post(this.$store.state.baseUrl + "/api/alert/"+alert.id+"/delete")
        .then((response) => {
          if (response.data.rc == 1) {
            for (let index = 0; index < this.product.alerts.length; index++) {
              const element = this.product.alerts[index].id;
              if(element === alert.id) {
                this.product.alerts.splice(index,1)
              }
            }
          } else {
            this.error = true
          }
        })
        .catch((error) => {
          console.log(error)
          this.error = true;
          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateStock(stockIn) {
      this.founded = false
      for (let index = 0; index < this.product.stocks.length; index++) {
        const element = this.product.stocks[index].id;
        if(element === stockIn.id) {
          this.founded = true;
          this.product.stocks[index] = stockIn
        }
      }

      if(!this.founded) {
        this.product.stocks.push(stockIn)
      }
      if(stockIn.quantity <= 0)
        alert('Te has quedado sin existencias del Stock añadido') 
      
    },
    deleteStock(stockIn){
      for (let index = 0; index < this.product.stocks.length; index++) {
        const element = this.product.stocks[index].id;
        if(element === stockIn.id) {
          this.founded = true;
          this.product.stocks.splice(index,1)
        }
      }
    },
    sumQuantity(status = false){
      if(status)
        return _.sumBy(this.filteredByStatus(status),'quantity')
      return _.sumBy(this.product.stocks,'quantity')
    },
    filteredByStatus(status){
      if(status == "incoming")
        return  _.filter(this.product.stocks, { state: 'incoming' });
      if(status == "reserved")
        return  _.filter(this.product.stocks, (stock) =>
          this.reserved.includes(stock.state)
        );
      if(status == "available")
        return  _.filter(this.product.stocks, (stock) =>
          this.available.includes(stock.state)
        );


    }
  }
};
</script>
