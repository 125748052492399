<template>
  <div class="container">
    <h3>Configuración</h3>
  </div>

</template>

<script>

export default {
  name: "Config",
  components: {
  },
};
</script>
