<template>
  <div  class="card p-3">
    <div class="d-flex justify-content-between">
      <div class="fw-bold text-lg mb-auto mt-auto">Stocks ({{ this.sumQuantity() }})</div>

      <addStock v-if="editable" @addStock="this.updateStock($event)" :product="this.product" :product_id="this.product.id" />
    </div>
    <hr />
    <div class=" table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Entrante ({{ this.sumQuantity('incoming') }})</th>
            <th>En Stock ({{ this.sumQuantity('available') }})</th>
            <th>Reserva ({{ this.sumQuantity('reserved') }})</th>
            <!-- <th>Total</th> -->
          </tr>
        </thead>
        <tbody  class="">
          <tr>
            <td>
              <div  class="d-flex flex-column">
                  <Stock
                    v-for="(stock) in this.filteredByStatus('incoming')"
                    :key="stock"
                    :stock="stock"
                    @changeStock="updateStock($event)"
                    @deleteStock="deleteStock($event)"
                  /> 
              </div>
            </td>
            <td>
              <div  class="d-flex flex-column">
                  <Stock
                    v-for="(stock) in this.filteredByStatus('available')"
                    :key="stock"
                    :stock="stock"
                  /> 
              </div>
            </td>
            <td>
              <div  class="d-flex flex-column">
                  <Stock
                    v-for="(stock) in this.filteredByStatus('reserved')"
                    :key="stock"
                    :stock="stock"
                  /> 
              </div>
            </td>
            
          </tr>
          
        </tbody>
    </table>
    <div class="w-100">
      Coste: {{ (this.sumQuantity()*this.product.real_seller_price).toFixed(2) }}€
    </div>
    <div class="w-100">
      Valor: {{ (this.sumQuantity()*this.product.real_client_price).toFixed(2) }}€
    </div>


    </div>
  
  </div>
</template>

<script>
import Stock from "@/views/components/Stock.vue";
import addStock from "@/views/products/addStock.vue";
import _ from 'lodash';

export default {
  props: {
    productIn: {

    },
    editable: {
      default: false
    }
  },
  components: {
    Stock,
    addStock
  },
  data() {
    return {
      product: {
        stocks: []
      },
      founded: false,
      available: ['draft'], 
      reserved: 
        [
          'pending',
          'confirmed',
          'preparing',
          'prepared',
          'sended',
        ]


    }
  },
  mounted() {
    this.product = this.productIn
  },
  methods: {
    updateStock(stockIn) {
      console.log('updateStock',stockIn)
      this.founded = false
      for (let index = 0; index < this.product.stocks.length; index++) {
        const element = this.product.stocks[index].id;
        if(element === stockIn.id) {
          this.founded = true;
          this.product.stocks[index] = stockIn
        }
      }

      if(!this.founded) {
        this.product.stocks.push(stockIn)
      }
      if(stockIn.quantity <= 0)
        alert('Te has quedado sin existencias del Stock añadido') 
      
    },
    deleteStock(stockIn){
      for (let index = 0; index < this.product.stocks.length; index++) {
        const element = this.product.stocks[index].id;
        if(element === stockIn.id) {
          this.founded = true;
          this.product.stocks.splice(index,1)
        }
      }
    },
    sumQuantity(status = false){
      if(status)
        return _.sumBy(this.filteredByStatus(status),'quantity')
      return _.sumBy(this.product.stocks,'quantity')
    },
    filteredByStatus(status){
      if(status == "incoming")
        return  _.filter(this.product.stocks, { state: 'incoming' });
      if(status == "reserved")
        return  _.filter(this.product.stocks, (stock) =>
          this.reserved.includes(stock.state)
        );
      if(status == "available")
        return  _.filter(this.product.stocks, (stock) =>
          this.available.includes(stock.state)
        );


    }
  }
};
</script>
