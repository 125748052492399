<template>
  <div class="container mt-4">
    <div v-if="!this.loading">
      <form  @submit.stop.prevent="this.save()">
        <!-- P y E -->
        <div class="row ">
          <div class="d-flex justify-content-between">
            <div>
                <div class="d-flex align-items-center">
                  <div v-if="this.new" class="h3 p-0 m-auto me-3">Crear Producto</div> 

                  <div v-else class="h3 p-0 m-auto me-3">Editar Producto #{{ this.data.sku }}</div>  
                </div>
                <router-link
                  v-if="this.$route.name == 'EditProduct'"

                  class="back-button"
                    :to="{
                      name: 'ViewProduct',
                      params: {
                        product_id: this.data.id
                      }
                    }"
                    > 
                  <i class="bi bi-arrow-left "></i> Ver Producto
                </router-link>
                <router-link
                v-if="this.$route.name == 'CreateProduct'"

                  class="back-button"
                    :to="{
                      name: 'Products'
                    
                    }"
                    > 
                  <i class="bi bi-arrow-left "></i> Productos
                </router-link>

              </div>
              <!-- BOTONES -->
              <div  class="d-flex">
                <button
                v-if="this.$store.state.auth.rol === 'worker' && !this.data.deleted_at && this.data.id"
                @click="deleteProduct()"
                type="button"
                class="btn btn-danger m-auto ps-2 pe-2 ms-2"
              >
              <i class="bi bi-trash-fill"></i>
              Eliminar
              </button>
              <button
                type="submit"
                v-if="!this.data.deleted_at "
                class="btn btn-primary m-auto ps-7 pe-7 ms-2"
                > 
                Guardar
              </button>
              
              </div>
          </div>
          <div v-if="error" class="alert alert-danger mt-4">
            <div>
              {{ error }}
            </div>
          </div> 
        </div>
        

        <div class="row ">
          <!-- Detalles del pedido -->
          <div class="col-md-12 mt-4">
            <DetailsProduct @input-change="handleInputChange" :editable="true"  :info="data"/>
          </div>
          <!-- Cliente -->
          


          
        </div>

        <div class="row mb-5">
          <div class="col-md-4 ">
            <ClientCard  v-if="this.$store.state.auth.rol == 'worker'" @selected="handleSelectedUser" class="mt-4" :filter="'sellers'" :data="data.seller" :title="'Vendedor'"/>
          </div>
          <div class="col-md-8" :class="{'col-md-12' : this.$store.state.auth.rol == 'seller'}">
            <EquivalenceTable @input-change="handleInputChange" :enableOptions="true" class="mt-4" :data="data"/>
          </div>
        </div>
        <div v-if="$route.name == 'EditProduct'" class="row ">
          <!-- Detalles del pedido -->
          <div class="col-md-8 mt-4">
            <Stocks :editable="false" :productIn="data"/>
          </div>
          <!-- Detalles del pedido -->
          <div class="col-md-4 mt-4">
            <Alerts :productIn="data"/>
          </div>
        </div>
        
            

       



    </form>
       
    </div>
    <div v-if="loading" class="d-flex justify-content-center ">
      <Cargador/>
    </div> 
    
  </div>
</template>

<script>
import DetailsProduct from "@/views/products/detailsTable.vue";
import EquivalenceTable from "@/views/products/EquivalenceTable.vue";
// import Stocks from "@/views/products/Stocks.vue";

import ClientCard from "@/views/orders/order/ClientCard.vue";
// import HistoricCard from "@/views/orders/order/HistoricCard.vue";
import Stocks from "@/views/products/Stocks.vue";
import Alerts from "@/views/products/AlertsStock.vue";


import axios from "axios";

export default {
  components: {
    DetailsProduct,
    ClientCard,
    // HistoricCard,
    EquivalenceTable,
    Alerts,
    Stocks
  }, 
  data() {
    return {
      loading: true,
      error: false,
      new: true,
      data: {
          "id": null,
          "client_price": null,
          "seller_price": null,
          "state": "draft",
          // "link": "",
          "client_direction_send": "",
          "client_direction_billing": "",
          "private_notes_seller": null,
          "private_notes_worker": null,
          "sended_on": null,
          "seller_id": null,
          "client_id": null,
          "created_at": "",
          "updated_at": "",
          "sku": null,
          "deleted_at": "",
          "real_client_price": 0,
          "real_seller_price": 0,
          "equivalence_box_to_units" :null,
          "client": {
              "id": null,
              "name": "",
              "surname": "",
              "email": "",
              "email_verified_at": null,
              "no_mail": null,
              "rol": "",
              "nif": null,
              "user_id": null,
              "telephone": "",
              "private_notes_for_workers": null,
              "invoice_direction": "",
              "delivery_direction": "",
              "image_url": "",
              "canReciveNotifications": null,
              "canReciveEmails": null,
              "notifyChangeState": null,
              "notifyAlertsStock": null,
              "color": null,
              "email_logistic": "",
              "telephone_logistic": "",
              "email_administracion": "",
              "telephone_administracion": "",
              "created_at": "",
              "updated_at": "",
              "company_name": "",
              "cif": "",
              "fiscal_direction": "",
              "iban": "",
              "private_notes_for_sellers": ""
          },
          "seller": {
              "id": null,
              "name": "",
              "surname": "",
              "email": "",
              "email_verified_at": null,
              "no_mail": null,
              "rol": "",
              "nif": null,
              "user_id": null,
              "telephone": "",
              "private_notes_for_workers": null,
              "invoice_direction": "",
              "delivery_direction": "",
              "image_url": "",
              "canReciveNotifications": null,
              "canReciveEmails": null,
              "notifyChangeState": null,
              "notifyAlertsStock": null,
              "color": "",
              "email_logistic": "",
              "telephone_logistic": "",
              "email_administracion": "",
              "telephone_administracion": "",
              "created_at": "",
              "updated_at": "",
              "company_name": "",
              "cif": "",
              "fiscal_direction": "",
              "iban": "",
              "private_notes_for_sellers": ""
          },
          "selections": [],
          "historics": []
      },
      
      
    };
    
  },
  beforeRouteLeave (to, from, next) {
    if(this.isDataChanged()) {
      if(confirm("¿Estas seguro de salir? Tienes cambios sin guardar"))
        next()
    } else
    next()

  },
  mounted() {
    if(this.$route.params.product_id && this.$route.name == 'EditProduct') {
      this.new = false
      this.loadData();
    } else {
      this.loading = false
    }
     
  },
  methods: {
    handleSelectedUser(user) {
        console.log(user)
        this.data.seller = user
        this.data.user_id = user.id
    },
    handleInputChange(key, value) {
      // Handle the emitted value in the parent component
      
      // You can update the data in the parent component as needed.
      // For example, if you have an object called 'data' in the parent component:
      this.data[key] = value;
    },

    isDataChanged() {
      // Use JSON.stringify to compare the two objects as strings
      return (JSON.stringify(this.data) !== JSON.stringify(this.original) || (JSON.stringify(this.data.selections) !== JSON.stringify(this.original.selections)));
    },
    deleteProduct() {
      if (confirm("¿Estás seguro de que deseas eliminar éste producto?")) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + this.$store.state.token;

        axios
          .post(this.$store.state.baseUrl + `/api/product/delete/${this.data.id}`)
          .then((response) => {
            if (response.data.rc == 1) {
              // Orden eliminada correctamente, redirige a la pantalla de la orden
              this.$router.push({
                name: 'ViewProduct',
                params: {
                  product_id: this.data.id
                }
              });
            } else {
              // Error al eliminar la orden, muestra un mensaje de error
              this.error = "Error al eliminar la orden.";
            }
          })
          .catch(() => {
            // Error al hacer la llamada a la API, muestra un mensaje de error
            this.error = "Error al eliminar la orden.";
          });
      }
    },
    loadData() {
     
    
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .get(this.$store.state.baseUrl + `/api/products/${this.$route.params.product_id}`)
        .then((response) => {
          if (response.data.rc == 1) {
            this.data = response.data.data;
            this.original = { ...response.data.data };
          }
        })
        .catch(() => {
          this.error = "Error"
          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {

        console.log(this.data)
        
        if(this.loading)
          return

        if(this.data.user_id == null && this.$store.state.auth.rol == 'worker') {
          alert('Debes seleccionar un Vendedor')
          return ;
        }

        this.loading = true
        this.error = false

        var url = '/api/product/create'
        if(!this.new)
          url = "/api/product/edit/"+this.data.id


          
        this.data.client_price = this.data.real_client_price*100
        this.data.seller_price = this.data.real_seller_price*100


        axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
        axios
        
          .post(this.$store.state.baseUrl + url, this.data)
          .then((response) => {
            if (response.data.rc == 1) {
              // if is new we have to go to the new url 
                this.data = response.data.data;
                this.original = { ...response.data.data };
                this.$router.push(
                {
                  name:'ViewProduct',
                  params: {
                    product_id: response.data.data.id
                  }
                })
            
            
              
            } 
            else if (response.data.rc == 14) {
              this.error = "Éste código EAN ya ha sido utilizado"
              this.loading = false;

            }
            else {
              this.error = response.data.data?? "Error"
              this.loading = false;

            }
          })
          .catch(() => {
            this.error = "Error"
            this.loading = false;

            
          })
          .finally(() => {
            // this.loading = false;
          });
      }
    
  }
};
</script>
