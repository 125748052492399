<template>
  <div>
    <div class="d-flex addSelection">
      
      
        <div>
          <div  class="d-flex ">
            <div class="addQuantuty">
              <div class="MoreAndLess">
                <i class="bi bi-plus-circle" @click="operation('more')"></i>
                <i class="bi bi-dash-circle" @click="operation('less')" :class="{'unavailable-content' : this.num<=0 }"></i>
              </div>
              <input type="number" :min="0" @keydown.enter.prevent="operation" @change="operation()" class="inputQuantuty" v-model="num"  >

            </div>
            <!-- <span class="numberSelection" :class="{'text-lila' : this.num>0}">{{ num }}</span> -->
            <span class="m-auto x-quantities">X</span>
            <select v-model="this.type" class="form-select pe-5 ms-2 me-2 m-auto" @change="changeResult()">
              <option :value="'unit'">Unidades</option>
              <option :disabled="this.product.equivalence_box_to_units == null" :value="'box'">Cajas</option>
            </select>
            
          </div>
        </div>
          

    </div>
    <div v-if="type == 'box'" class="text-left helperSelection ">
      = {{ result }} Unidades <i class="bi bi-question-circle" style="font-size: 12px;" @mouseover="upHere = !upHere"></i>
      <div class="informer" v-if="upHere">{{ product.equivalence_box_to_units }} unidades por caja </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    estado: {
      type: String,
    },
    editable: {
      default: false
    },
    product: {
      required:true
    },

  },
  data() {
    return {
      type: "unit",// or box
      num: 0,
      result: 0, // result in UNITS,
      upHere: false
    }
  },
  mounted() {
    
  },
  
  methods: {
    changeResult() {
      this.result = 0
      if(this.type == 'box' ){
        this.result = this.num*this.product.equivalence_box_to_units
      } else {
        this.result = this.num
      }
      this.emitir();
    },
    operation(operation) {
      if(operation == 'more' && this.num>=0) {
        this.num++
      }
      if(operation == 'less' && this.num>0) {
        this.num--
      }
      if(this.type == 'box' ){
        this.result = this.num*this.product.equivalence_box_to_units
      } else {
        this.result = this.num
      }
      this.emitir();
    },
    roundNumber(num) {
      return num.toFixed(2)
    },
    emitir() {
      this.$emit('selected', {
        product: this.product,
        product_id: this.product.id,
        type: this.type,
        quantity: this.num,
        real_client_price: this.roundNumber((this.num*this.product.client_price)/100)
      })
    }
  },
};
</script>
