<template>
  <div>
    <div  class="card p-4">
      <div class="w-100 d-flex justify-content-between">
        <div class="fw-bold text-lg">Notificaciones enviadas</div>
        <div>
          <button @click="getData()" v-if="!this.loading && notifications.length == 0" class="btn btn-secondary">Cargar</button>
        </div>
      </div>
      <ul v-if="!loading" class="list-group mt-3">
        <li v-for="notification in this.notifications" :key="notification.id" class="list-group-item">
          <NotificationDetail :data="notification" />
        </li>
        <small v-if="notifications.length>0" >{{ notifications.length }} en total</small>
      </ul>
      <Cargador v-else></Cargador>

    </div>
  </div>
</template>

<script>

import NotificationDetail from "@/views/orders/order/notificationDetailed.vue";
import axios from "axios";


export default {
  components: {
    NotificationDetail
  }, 
  data() {
    return {
      loading: false,
      notifications: []
      
    };
    
  },
  props: {
    data: {
      required: true
    }
  },
  methods: {
    getData() {
      if(this.loading)
        return;
      this.loading = true
      axios
        .get(this.$store.state.baseUrl + `/api/order/${this.data.id}/notifications`,{
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+this.$store.state.token
          }
        })

        .then(response => {
          if (response.data.rc == 1) {
            this.notifications = response.data.data

          } 
        })
        .catch(error => {
          // Manejar errores de la solicitud si es necesario
          this.error = error?? 'Error'
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }


  
  
};
</script>
