import { createRouter, createWebHistory } from "vue-router";
// ANTIGUOS
import DashboardOld from "@/views/Dashboard.vue";
import Tables from "@/views/Tables.vue";
import Billing from "@/views/Billing.vue";
import VirtualReality from "@/views/VirtualReality.vue";
// import Profile from "@/views/Profile.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
// NUEVOS
import Orders from "@/views/orders/index.vue";
import Dashboard from "@/views/dashboard/index.vue";
import Products from "@/views/products/index.vue";
import Clients from "@/views/clients/index.vue";
import Vendors from "@/views/vendors/index.vue";
import Config from "@/views/config/index.vue";
import Profile from "@/views/profile/index.vue";
// view product
import ViewProduct from "@/views/products/product/ViewProduct.vue"; 
import CreateProduct from "@/views/products/product/CreateProduct.vue";
//view order
import ViewOrder from "@/views/orders/order/ViewOrder.vue";
import EditOrder from "@/views/orders/order/EditOrder.vue";
//workers order view
// import WorkersViewOrder from "@/views/orders/workers/ViewOrder.vue";
// import WorkersEditOrder from "@/views/orders/workers/EditOrder.vue";
// Files
import uploadFile from "@/views/files/uploadFile.vue";


// Clients 
import CreateClient from "@/views/clients/client/CreateClient.vue";

// Vendors 
import CreateVendor from "@/views/vendors/vendor/CreateVendor.vue";

//Notificatios
import Notifications from "@/views/notifications/index.vue";
// others
import cookie from 'cookiejs';
import multiguard from 'vue-router-multiguard';
//
import StablishPassword from "@/views/auth/stablishPassword.vue";






const hasToken = function(to, from, next) {
  var c = cookie.get('authToken')

  if(c == false || c == undefined)
    return router.push({ name: 'Sign In' });
  // checkemamos la cookie
  return next();
}

const routes = [
  // NUEVAS/DEFINITIVAS
  
  {
    path: "/dashboard",
    beforeEnter: multiguard([hasToken]),
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: "/notifications",
    beforeEnter: multiguard([hasToken]),
    name: 'Notifications',
    component: Notifications
  },
  {
    path: "/orders",
    beforeEnter: multiguard([hasToken]),
    name: 'Orders',
    component: Orders,
    props: {
      stateFilterInit: 'in_course'
    }
  },
  {
    path: "/orders/:order_id",
    beforeEnter: multiguard([hasToken]),
    name: 'ViewOrder',
    component: ViewOrder
  },
  {
    path: "/orders/create",
    beforeEnter: multiguard([hasToken]),
    name: 'CreateOrder',
    component: EditOrder
  },
  {
    path: "/orders/edit/:order_id",
    beforeEnter: multiguard([hasToken]),
    name: 'EditOrder',
    component: EditOrder
  },
  // {
  //   path: "/orders/workers/:order_id",
  //   beforeEnter: multiguard([hasToken]),
  //   name: 'WorkersViewOrder',
  //   component: WorkersViewOrder
  // },
  // {
  //   path: "/orders/workers/edit/:order_id",
  //   beforeEnter: multiguard([hasToken]),
  //   name: 'WorkersEditOrder',
  //   component: WorkersEditOrder
  // },
  {
    path: "/products",
    beforeEnter: multiguard([hasToken]),
    name: 'Products',
    component: Products
  },
  {
    path: "/products/create",
    beforeEnter: multiguard([hasToken]),
    name: 'CreateProduct',
    component: CreateProduct
  },
  {
    path: "/products/edit/:product_id",
    beforeEnter: multiguard([hasToken]),
    name: 'EditProduct',
    component: CreateProduct
  },
  {
    path: "/products/:product_id",
    beforeEnter: multiguard([hasToken]),
    name: 'ViewProduct',
    component: ViewProduct
  },
  
  {
    path: "/sellers",
    beforeEnter: multiguard([hasToken]),
    name: 'Vendors',
    component: Vendors
  }, 
  {
    path: "/seller/:seller_id",
    beforeEnter: multiguard([hasToken]),
    name: 'ViewVendor',
    component: CreateVendor
  }, 
  {
    path: "/sellers/edit/:seller_id",
    beforeEnter: multiguard([hasToken]),
    name: 'EditVendor',
    component: CreateVendor
  }, 
  {
    path: "/vendors/create",
    beforeEnter: multiguard([hasToken]),
    name: 'CreateVendor',
    component: CreateVendor
  }, 
  {
    path: "/clients",
    beforeEnter: multiguard([hasToken]),
    name: 'Clients',
    component: Clients
  }, 
  {
    path: "/clients/:client_id",
    beforeEnter: multiguard([hasToken]),
    name: 'ViewClient',
    component: CreateClient
  },
  {
    path: "/clients/create",
    beforeEnter: multiguard([hasToken]),
    name: 'CreateClient',
    component: CreateClient
  },
  {
    path: "/upload/:user_id",
    beforeEnter: multiguard([hasToken]),
    name: 'uploadFile',
    component: uploadFile
  },
  {
    path: "/clients/edit/:client_id",
    beforeEnter: multiguard([hasToken]),
    name: 'EditClient',
    component: CreateClient
  },
  {
    path: "/config",
    beforeEnter: multiguard([hasToken]),
    name: 'Config',
    component: Config
  },
  {
    path: "/profile",
    beforeEnter: multiguard([hasToken]),
    name: 'Profile',
    component: Profile
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/password",
    name: "New Password",
    component: StablishPassword,
  },







  // ANTIGUAS
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard-old",
    name: "DashboardOld",
    component: DashboardOld,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },

  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
