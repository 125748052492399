<template>
    <form @submit.stop.prevent="this.upload()" class="container">
      <h5>Subir Archivo</h5>
      <div v-if="!success" class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputEmail1">Nombre Documento</label>
                  <input type="text" class="form-control" required v-model="form.title"  placeholder="Nombre documento">
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Detalles (Opcional)</label>
                  <input v-model="form.details"  type="text" class="form-control"  placeholder="Nombre documento" >
                  <small class="form-text text-muted">Detalles-Subtitulo de documento</small>

                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="formFile" class="form-label">Subir Archivo</label>
                  <input class="form-control" type="file" 
                        id="file-upload" 
                        required
                        accept=".doc, .docx, .pdf, .ppt, .pptx, .xls, .xlsx"
                        name="file"
                        @change="handleFileUpload( $event )"   
                        size="15360000">
                </div>
                <div class="form-group">
                  <button class="btn btn-primary mt-4">
                    <div v-if="uploading" class="spinner-border" rol="status"></div>
                    <text v-else>Subir documennto</text>
                  </button>
                </div>
                <div v-if="this.error" class="alert alert-danger">
                  Parece que ha habido algún error
                </div>
                
              </div>

            </div>
          
          </div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-success">
          Archivo subido correctamente
        </div>
      </div>
      
    </form>
</template>

<script>
import axios from "axios";
export default {
  name: "uploadFile",  
  data() {
    return {
      uploading: false,
      error: false,
      success:false,
      form: {
        file: null,
        title: null,
        details: null
      }

    }
  },
  props: {
    user_id: {
      required: true,
      default: null,
    }
  },
  methods: {
    handleFileUpload( event ){
      this.form.file = event.target.files[0];
      // if(this.form.file)
      //   this.imageUrl = URL.createObjectURL( this.form.file)
      // else
      // this.imageUrl = null
    },
    upload(){
      if(this.uploading)
        return;

      this.uploading = true;
      // create the form to send
      let formData = new FormData();
      // add the image
      if(this.form.file !== null)
        formData.append('file', this.form.file);
      
      // add all the datas
      for (let key in this.form) {
        if (key !== 'file' && key !== 'image' && this.form[key] !== null) {
          formData.append(key, this.form[key]);
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .post(this.$store.state.baseUrl + '/api/user/'+this.$route.params.user_id+'/upload', formData)
        .then((response) => {
          if (response.data.rc == 1) {
            this.success = true
          } else {
            this.error = true
          }
         
        })
        .catch(() => {
          this.error = true;
          
        })
        .finally(() => {
          this.uploading = false
        });
      
    }
  }
};
</script>
