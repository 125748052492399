<template>
  <div class="w-100">
    <timeline-list class="h-100" title="Histórico de pedido">
      <timeline-item v-for="(item, index) in data.historics" :key="index"
        color="success"
        icon="bell-55"
        :title="item.title"
        :date-time="convertDateTime(item.created_at).date +' '+ convertDateTime(item.created_at).time"
      />
    
    </timeline-list>
  </div>
</template>

<script>


import TimelineList from "@/views/components/TimelineList.vue";
import TimelineItem from "@/views/components/TimelineItem.vue";

export default {
  components: {
    // Stock,
    
    TimelineList,
    TimelineItem,
  }, 
  data() {
    return {
      
    };
    
  },
  props: {
    data: {
      required: true
    }
  },
  methods: {
    convertDateTime(dateTimeString) {
      const dateObj = new Date(dateTimeString);

      // Extracting date values
      const year = dateObj.getFullYear() % 100;
      const month = dateObj.getMonth() + 1; // Month is zero-based
      const day = dateObj.getDate();

      // Extracting time values
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();

      return {
        date: `${year}/${month.toString().padStart(2, "0")}/${day
          .toString()
          .padStart(2, "0")}`,
        time: `${hours}:${minutes.toString().padStart(2, "0")}`,
      };
    },
  }


  
  
};
</script>
