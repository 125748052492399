<template>
  <div class="mb-3 timeline-block" :class="darkMode">
    <span class="timeline-step" :class="darkMode ? 'bg-dark' : ''">
      <i class="ni text-gradient" :class="`ni-${icon} text-${color}`"></i>
    </span>
    <div class="timeline-content">
      <h6
        class="me-3 text-sm font-weight-bold"
        :class="darkMode ? 'text-white' : 'text-dark'"
      >
        {{ title }}
      </h6>
      <p class="mt-1 mb-0 text-xs text-secondary font-weight-bold">
        {{ dateTime }}
      </p>
      
    </div>
  </div>
</template>
<script>
export default {
  name: "TimelineItem",
  props: {
    color: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    badges: {
      type: Array,
      default: () => [],
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
