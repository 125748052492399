
<template>
  <div class="card p-4 mt-4">
    <div class="d-flex justify-content-between">
      <div class="fw-bold text-lg mb-2">Productos </div>
      <SelectProduct v-if="this.editable" @selecteds="addSelecteds" :selectionsIn="this.list"/>
    </div>


    
    <!-- {{ this.list }} -->

      <draggable
        :list="list"
        :disabled="!enabled"
        item-key="name"
        class="list-group"
        ghost-class="ghost"
        :move="checkMove"
        @start="dragging = true"
        @end="dragging = false"
      >
        <template #item="{ element }">
          <div class="list-group-item" :class="{ 'not-draggable': !enabled }">
            <div class="d-flex " >
              <div class="d-flex align-items-center">
                <i v-if="this.editable" class="bi bi-list cursor-all-scroll"></i>
                <Selection :selection="element" :showPrice="true" />

              </div>
              <div class="d-flex justify-content-center  m-auto me-0 text-right">
                <span class="p-0 m-0 align-self-center text-right">{{ (element.product.real_client_price* element.quantity).toFixed(2)}}€</span>
                <i v-if="this.editable" @click="removeElementOfList(element)" class="bi bi-trash-fill m-auto ms-3 pointer"></i>
              </div>
              

            </div>
          </div>
        </template>
      </draggable>

      <small>{{ list.length }} selecciones en total</small>

  </div>
</template>

<script>


import Selection from "@/views/components/Selections.vue";
import SelectProduct from "@/views/components/SelectProducts.vue";

// ESTE COMPONENTE SIRVE PARA VER YO EDITAR Y AÑADIR PRODUCTOS 
export default {
  name: "selections",
  order: 0,
  components: {
    Selection,
    SelectProduct
  },
  data() {
    return {
      list: [
        
      ],
      enabled: true,
      dragging: false
    };
  },
  created() {
    this.list = this.info
  },
  props: {
    info: {
      
    },
    editable: {
      default: true
    },
    canAdd: {
      default: false
    }
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  methods: {
    removeElementOfList(element) {
      const indice = this.list.findIndex(elemento => elemento.id == element.id);
      if (indice !== -1) {
        this.list.splice(indice, 1);
      }
      this.$emit('selecteds',this.list)
    },
    addSelecteds(data){
      this.list = this.list.concat(data)
      this.$emit('selecteds',this.list)
    },  
    checkMove(e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    }
  }
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.not-draggable {
  cursor: no-drop;
}
</style>