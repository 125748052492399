<template>
  <div v-if="data !== null" class="ClientInfo row">
    <div class="col d-flex">
      <img v-if="data.image_url" :src="data.image_url" class="clientInfo-img shadow-sm border-radius-lg" />
      <!-- <img v-else :src="'https://ui-avatars.com/api?name='+data.name+'&background=6EF1D0&color=344767&size=50'" class="clientInfo-img shadow-sm border-radius-lg" /> -->
      <img v-else :src="'https://ui-avatars.com/api?name='+data.name+'&background=8392AB&color=fff&size=34'" class="clientInfo-img shadow-sm border-radius-lg" />

    </div>
    <div class="col">
      <div class="d-flex flex-column">
        <div class="row fw-bold text-nowrap text-md">
          {{ data.name }}
        </div>
        <div v-if="!data.no_mail" class="row text-xs">
          {{ data.email }}
        </div>
        <div v-if="this.showId" class="row text-xxs fw-light">#0{{ data.id }}</div>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>
<script>
export default {
  props: {
    data: {
      required: true
    },
    showId: {
      default: true,
      type: Boolean
    }
  },
};
</script>
