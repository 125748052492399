<template>
  <div >
    <router-link
    class="product-card d-flex align-items-center"
        :to="{
          name: 'ViewProduct',
          params: {
            product_id: selection.product_id
          },
        }"
      >
      <div id="quantity-fix-width" class="ps-4 mt-1">
        <div class="text-lg m-0 p-0  text-bold text-center">
          {{ selection.quantity }}
          <span class="">X</span>
        </div>
        <div class="text-sm m-0 p-0 text-start text-center">
          {{ this.tranlsateType(selection.type) }}
        </div>
      </div>
      <img class="product-img img-smaller" v-if="selection.product.image_url" :src="selection.product.image_url" alt="" />
      <img v-else class="product-img img-smaller mb-1" :src="this.$store.state.baseUrl+'/products/default.png'" alt="" />

      <div class="ms-1 product-info mt-1">
        <!-- <div class=""> -->
          <div class="fw-bold p-0 d-flex justify-content-start  text-bold text-sm">
           <span class="me-2"># {{ selection.product.sku }} </span> {{ selection.product.name }}
          </div>
          <div class="">
            <div
              v-if="showPrice"
              class="p-0 text-sm "
            >
              {{ selection.product.real_client_price }} €
            </div>

            
          </div>
        <!-- </div> -->
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  props: ['showPrice','selection'],
  methods: {
    tranlsateType(type) {
      const translations = this.$store.state.translations;
      if (type in translations) {
        return translations[type].name;
      }
    }
  }
};
</script>
