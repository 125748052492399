<template>
  <div>
    <div @click="openStock = !openStock" class="btn btn-secondary">
      <i class="bi bi-clipboard-plus-fill"></i> Añadir Stock
    </div>
    <form v-if="openStock" class="floatingPop" @submit.stop.prevent="this.addStock()" >
    <h5>Añadir Stock</h5>
    <small>Si ya existe un Stock del mismo tipo éste se sumará al anterior</small>
    <div class="d-inline-flex ">
      <div class="col-md-3 p-0 m-0 d-flex d-flex">
          <div class="input-group mt-2">
            <input
              type="number"
              v-model="form.quantity"
              placeholder="1"
              class="form-control "
            />
          </div>
          <text class="m-auto ms-1">  X  </text>

        </div>
        <div class="form-group m-0 mt-auto ms-1 col-md-3">
          <select
            class="form-control "
            id="quantityType"
            v-model="form.type"
          >
            <option
              :value="type.value"
              class="d-flex justify-content-center align-middle text-sm"
              v-for="(type, index) in this.getStockTypeOptions()"
              :key="index"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class=" form-group m-0 mt-auto ms-1 col-md-5 d-flex">
          <text class="m-auto">de  </text>
          <select
            class="form-control ms-1 "
            id="quantityType"
            v-model="form.state"
          >
            <option
              :value="type.value"
              class="d-flex justify-content-center align-middle text-sm"
              v-for="(type, index) in this.states()"
              :key="index"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
        
      </div>
      <div v-if="form.state == 'incoming'" class="col-md-12 p-0 mt-1">
        <div class="input-group mt-2">
          <VueDatePicker :required="true" :placeholder="'Fecha llegada'" :enable-time-picker="false" v-model="form.incoming_date" />
        </div>
      </div>
      
      
      <div v-if="error" class="alert alert-danger mt-3">
        Vaya, parece que hay un error
      </div>

      <div>

      </div>
      <div class="w-100 mt-3">
        <button @click="close()" type="button" class="btn btn-secondary">
        Cancelar
      </button>
     
      <button @click="addStock()" type="button" class="btn btn-primary ms-2">
        <div v-if="loading" class="spinner-border" rol="status"></div>
        <text v-else>Guardar</text>
      </button>
      </div>
      
    </form>
  </div>
  
</template>

<script>
import axios from "axios";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


export default {
  name: "addStock",
  components: {
    VueDatePicker
  },
  props: {
    product_id: null,
    product: {
      required: true
    }

  },
  data() {
    return {
      loading: false,
      openStock: false,
      error:false,
      form: {
        quantity: 1,
        type: "unit",
        state: "incoming"
      },
      stockType: [
        {
          name: "Unidad",
          value: "unit"
        },
        {
          name: "Caja",
          value: "box"
        },
        // {
        //   id: 3,
        //   name: "Palet",
        //   value: "pallet"
        // },
        // {
        //   name: "Suelto",
        //   value: "loose"
        // },
        // {
        //   name: "Preparado",
        //   value: "prepared"
        // },
        // {
        //   name: "Entrante",
        //   value: "incoming"
        // },
        // {
        //   name: "Devuelto",
        //   value: "returned"
        // }
      ],
      stockState: [
        {
          name: "Entrante",
          value: "incoming"
        },
        {
          name: "Borrador",
          value: "draft"
        },
        {
          name: "Pendiente",
          value: "pending"
        },
        {
          name: "Confirmado",
          value: "confirmed"
        },
        {
          name: "Preparación",
          value: "preparing"
        },
        {
          name: "Preparado",
          value: "prepared"
        },
        {
          name: "Enviado",
          value: "sended"
        },
        {
          name: "Entregado",
          value: "delivered"
        }
      ]
    }
  },
  created(){
    // si es vendedor solo incoming por defecto
    this.form.state = (this.$store.state.auth.rol == "worker")? "draft" :"incoming"

      


  },
  methods: {
    addStock() {



      this.loading = true
      this.error = false
      if(this.form.incoming_date)
        this.form.incoming_date = this.formatDate(this.form.incoming_date);
      if((this.form.state !== 'draft' && this.form.state !== 'incoming') && this.form.quantity <0)
        if(!confirm('Si restas stock de pedidos en curso éstos seguirán y al finalizar restarán o dejarán a 0 el stock. ¿Deseas continuar?')){
          this.loading = false;
          return;

        }


      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .post(this.$store.state.baseUrl + "/api/product/"+this.product.id+"/stock/add",this.form)
        .then((response) => {
          if (response.data.rc == 1) {
            console.log('response',response.data.data)
            this.$emit("addStock", response.data.data);
            this.openStock = false;
            this.form = {
              quantity: 1,
              type: "unit",
              state: this.form.state
            }

          } else {
            this.error = true
          }
        })
        .catch(() => {
          this.error = true;
          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.openStock = false
    },
    getStockTypeOptions() {
      if (this.product.equivalence_box_to_units === null) {
        // Si equivalence_box_to_units es nulo, solo mostrar 'Units'
        return [
          {
            name: "Unidad",
            value: "unit"
          }
        ];
      } else {
        // Si equivalence_box_to_units tiene un valor, mostrar 'Units' y 'Boxes'
        return [
          {
            name: "Unidad",
            value: "unit"
          },
          {
            name: "Caja",
            value: "box"
          }
        ];
      }
    },
    states() {
      if(this.$store.state.auth.rol == 'worker')
        return this.stockState;
      return [
          {
            name: "Entrante",
            value: "incoming"
          }
      ]
    },
    formatDate(date) {
      date = new Date(date)
      const day = date.getDate().toString().padStart(2, '0'); // Día con ceros iniciales si es necesario
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mes con ceros iniciales si es necesario
      const year = date.getFullYear().toString(); // Año en formato YYYY

      return `${day}-${month}-${year}`;
    }
  }
};
</script>
