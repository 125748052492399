<template>
  <div>
    <div v-if="type=='box'" class="Cargador">
      <img :width="width" :src="logo" class="Loader-cargador" alt="Loading" />
      <span v-if="fontSize" :style="'font-size: '+fontSize" >Cargando</span>
    </div>
    <div v-if="type=='spinner'">
      <h1>Hola</h1>
      <span class="loader" role="status"></span>
    </div>
  </div>
  
</template>

<script>

import logo from "@/assets/img/loader.gif";

export default {

  
  name: "Cargador",
 
  props: {
    width: {
      default: 100
    },
    fontSize: {
      default: '12px'
    },
    type: {
      type: String,
      default: 'box'
    }
  },
  data() {
    return {
      logo
    }
  }

  
};
</script>
